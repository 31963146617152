<template>
  <div class="section-dark">
    <div class="section bacgroundImage"
         :style="setBG(sectionScroll.img1)" v-prlx.background="{
              direction: 'x',
              speed: 0.1,
              fromBottom: true,
            }">
      <div class="container">
        <div class="textScrollingLeft">
          Out of the box real estate investments
        </div>
      </div>

    </div>

    <div class="section bacgroundImage"
         :style="setBG(sectionScroll.img2)" v-prlx.background="{
              direction: 'y',
              speed: 0.1,
              fromBottom: true,
            }">

      <div class="container">
        <div class="textScrollingCenter">
          In large European cities
        </div>
      </div>
    </div>

    <div class="section bacgroundImage backgroundScrollingPosition1"
         :style="setBG(sectionScroll.img3)" v-prlx.background="{
              direction: 'x',
              preserveInitialPosition: true,
              speed: 0.1,
              fromBottom: true,
            }">

      <div class="container">
        <div class="textScrollingRight">
          Residential buildings
        </div>
      </div>

    </div>

    <div class="section bacgroundImage"
         :style="setBG(sectionScroll.img4)" v-prlx.background="{
              direction: 'y',
              speed: 0.1,
              fromBottom: true,
            }">
      <div class="container">
        <div class="textScrollingCenter">
          Hospitality
        </div>
      </div>
    </div>

    <div class="section bacgroundImage"
         :style="setBG(sectionScroll.img5)" v-prlx.background="{
              direction: 'x',
              speed: 0.1,
              fromBottom: true,
            }">
      <div class="container">
        <div class="textScrollingLeft">
          Office buildings
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: "ScrollingImage",
  data() {
    return {
      sectionScroll: {
        img1: require("@/assets/img/realisations/la-defence-tours-Pascal-slide-1.png"),
        img2: require("@/assets/img/realisations/milan.png"),
        img3: require("@/assets/img/realisations/services5.jpg"),
        img4: require("@/assets/img/realisations/gloden-visa-slide.png"),
        img5: require("@/assets/img/realisations/bg-contact.jpg"),
      }
    };
  },
  methods: {
    setBG(image) {
      return {
        backgroundImage: `url(${image})`
      };
    },
    changePrices() {
      this.personal = !this.personal;
    }
  }
};
</script>

<style lang="css"></style>
