import Vue from "vue";
import Router from "vue-router";

//  Main Pages

import Accueil from "./views/Accueil.vue";

import ProfileXM from "./views/abbaq/Profile-2.vue";
import ProfileXMEn from "./views/abbaq/Profile-xm-en.vue";
import ProfileLC from "./views/abbaq/Profile-3.vue";
import ProfileLCEn from "./views/abbaq/Profile-lc-en.vue";
import AboutUs from "./views/AboutUs.vue";
import NosProjets from "./views/NosProjets";

import NosRealisations from "./views/NosRealisations";
import ContactUs from "./views/abbaq/ContactUs.vue";
import Error from "./views/examples/Error.vue";

import MainFooter from "./layout/MainFooter.vue";
import MainFooterEn from "./layout/MainFooterEn.vue";
import NavBarAbbaq from "./layout/NavBarAbbaq";
import NavBarAbbaqEn from "./layout/NavBarAbbaqEn";
import ContacterNous from "./views/abbaq/ContacterNous";
import QuiSommesNous from "./views/QuiSommesNous";
import Strategy from "./views/Strategy";
import Projects from "./views/Projects";
import Home from "./views/Home";

Vue.use(Router);

export default new Router({
  mode: 'history',
  routes: [
    {
      path: "/",
      name: "accueil",
      components: { default: Accueil, header: NavBarAbbaq, footer: MainFooter },
      props: {
        header: { colorOnScroll: 400, navbarTransparent: true }
      }
    },
    {
      path: "/home",
      name: "home",
      components: { default: Home, header: NavBarAbbaqEn, footer: MainFooterEn },
      props: {
        header: { colorOnScroll: 400, navbarTransparent: true }
      }
    },
    {
      path: "/nos-projets",
      name: "nos-projets",
      components: { default: NosProjets, header: NavBarAbbaq, footer: MainFooter },
      props: {
        header: { colorOnScroll: 400, navbarTransparent: true }
      }
    },
    {
      path: "/nos-realisations",
      name: "nos-realisation",
      components: { default: NosRealisations, header: NavBarAbbaq, footer: MainFooter },
      props: {
        header: { colorOnScroll: 400, navbarTransparent: true }
      }
    },
    {
      path: "/xavier_mahieu",
      name: "xMAHIEU",
      components: { default: ProfileXM, header: NavBarAbbaq, footer: MainFooter },
      props: {
        header: { colorOnScroll: 400, navbarTransparent: true }
      }
    },
    {
      path: "/xavier_mahieu_en",
      name: "xMAHIEUEn",
      components: { default: ProfileXMEn, header: NavBarAbbaqEn, footer: MainFooter },
      props: {
        header: { colorOnScroll: 400, navbarTransparent: true }
      }
    },
    {
      path: "/lionel_charpentier",
      name: "lCharpentier",
      components: { default: ProfileLC, header: NavBarAbbaq, footer: MainFooterEn },
      props: {
        header: { colorOnScroll: 400, navbarTransparent: true }
      }
    },
    {
      path: "/lionel_charpentier_en",
      name: "lCharpentierEn",
      components: { default: ProfileLCEn, header: NavBarAbbaqEn, footer: MainFooterEn },
      props: {
        header: { colorOnScroll: 400, navbarTransparent: true }
      }
    },
    {
      path: "/about-us",
      name: "about-us",
      components: { default: AboutUs, header: NavBarAbbaqEn, footer: MainFooterEn },
      props: {
        header: { colorOnScroll: 400, navbarTransparent: true }
      }
    },
    {
      path: "/strategy",
      name: "strategy",
      components: { default: Strategy, header: NavBarAbbaqEn, footer: MainFooterEn },
      props: {
        header: { colorOnScroll: 400, navbarTransparent: true }
      }
    },
    {
      path: "/projects",
      name: "projects",
      components: { default: Projects, header: NavBarAbbaqEn, footer: MainFooterEn },
      props: {
        header: { colorOnScroll: 400, navbarTransparent: true }
      }
    },
    {
      path: "/qui-sommes-nous",
      name: "qui-sommes-nous",
      components: { default: QuiSommesNous, header: NavBarAbbaq, footer: MainFooter },
      props: {
        header: { colorOnScroll: 400, navbarTransparent: true }
      }
    },
    {
      path: "/contact-us",
      name: "contact-us",
      components: {
        default: ContactUs,
        header: NavBarAbbaqEn,
        footer: MainFooterEn
      },
      props: {
        header: { colorOnScroll: 0, navbarTransparent: false, type: "dark" }
      }
    },

    {
      path: "/contacter-nous",
      name: "contact-nous",
      components: {
        default: ContacterNous,
        header: NavBarAbbaq,
        footer: MainFooter
      },
      props: {
        header: { colorOnScroll: 0, navbarTransparent: false, type: "dark" }
      }
    },
    {
      path: '/*',
      name: "error",
      components: { default: Error, header: NavBarAbbaq, footer: MainFooter },
      props: {
        header: { colorOnScroll: 400, navbarTransparent: true }
      }
    },
  ],
  scrollBehavior: to => {
    if (to.hash) {
      return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  }
});
