<template>
  <div class=" wrapper">
    <parallax
      class="page-header header-filter header-small"
      parallax-active="true"
      :style="headerStyle"
    >
      <div class="container">
        <div class="md-layout">
          <div
            class="md-layout-item md-size-66 md-small-size-100 mx-auto text-center"
          >
            <h1 class="title">
              About
            </h1>
            <h4>
              A combination of local knowledge and international exposure from 2002
            </h4>
          </div>
        </div>
      </div>
    </parallax>
    <div class="main main-raised">
      <div class="section section-about-description text-center pb-0">
        <div class="container">
          <div class="md-layout">
            <div class="md-layout-item md-size-66 md-small-size-100 mx-auto">
              <h5 class="description">
                Abbaq is a real estate developer and investment company as well as an investment adviser. The company was founded in 2019 by Lionel Charpentier and Xavier Mahieu, both senior investment professionals. Abbaq repositions properties with a focus on medium size green-field or turn-around projects in growth markets in Europe. Abbaq also co-invests on larger projects and provides advisory services in project management and structured finance. The combination of local knowledge and international exposure creates value in an environment known for its complex practices.
              </h5>
            </div>
          </div>
        </div>
      </div>
      <div class="section section-about-team section-team-1 pb-0">
        <div class="container">
          <div class="md-layout">
            <div class="md-layout-item md-size-50 md-small-size-100">
              <profile-card
                card-plain
                card-avatar
                data-aos="fade-up"
                data-aos-easing="mk-easing"
                data-aos-duration="750"
                data-aos-delay="500"
                :shadow-normal="false"
                :no-colored-shadow="false"
                :card-image="aboutTeam.aboutTeam2"
              >
                <template slot="cardContent">
                  <h4 class="card-title">
                    Xavier MAHIEU
                  </h4>
                  <h6 class="card-category text-muted">
                    CO-Founder
                  </h6>
                  <p class="card-description">
                    Xavier has, for several years, participated in the national and European development of fund manager CBRE Investors, carrying out numerous acquisitions, rentals and resales of all types of assets.
                  </p>
                  <md-button
                      href="/xavier_mahieu_en"
                      class="md-warning md-round"
                  >
                    <md-icon>arrow_forward_ios</md-icon> see more
                  </md-button>
                </template>
                <template slot="cardAction">
                  <md-button
                      href="mailto:xmahieu@abbaq.fr"
                      class="md-mail md-just-icon md-simple"
                  >
                    <i class="fa fa-envelope" />
                  </md-button>
                  <md-button
                      href="https://www.linkedin.com/in/xavier-mahieu-89299035/"
                      class="md-linkedin md-just-icon md-simple"
                  >
                    <i class="fab fa-linkedin" />
                  </md-button>
                </template>
              </profile-card>
            </div>
            <div class="md-layout-item md-size-50 md-small-size-100">
              <profile-card
                card-plain
                card-avatar

                data-aos="fade-up"
                data-aos-easing="mk-easing"
                data-aos-duration="750"
                data-aos-delay="800"
                :shadow-normal="false"
                :no-colored-shadow="false"
                :card-image="aboutTeam.aboutTeam3"
              >
                <template slot="cardContent">
                  <h4 class="card-title">
                    Lionel CHARPENTIER
                  </h4>
                  <h6 class="card-category text-muted">
                    CO-Founder
                  </h6>
                  <p class="card-description">
                    Lionel was instrumental in many major real estate transactions executed by Apollo in continental Europe.
                  </p>
                  <md-button
                      href="/lionel_charpentier_en"
                      class="md-info md-round"
                  >
                    <md-icon>arrow_forward_ios</md-icon> See more
                  </md-button>
                </template>
                <template slot="cardAction">
                  <md-button
                      href="mailto:lcharpentier@abbaq.fr"
                      class="md-mail md-just-icon md-simple"
                  >
                    <i class="fa fa-envelope" />
                  </md-button>
                  <md-button
                      href="https://www.linkedin.com/in/charpentier-lionel-73a9356/"
                      class="md-linkedin md-just-icon md-simple"
                  >
                    <i class="fab fa-linkedin" />
                  </md-button>
                </template>
              </profile-card>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AOS from "aos";
import "aos/dist/aos.css";
import Testimonials from "./abbaq/Testimonials";
import { ProfileCard } from "@/components";
import Mixins from "@/plugins/basicMixins";

export default {
  components: {
    ProfileCard
  },
  mixins: [Mixins.HeaderImage],
  bodyClass: "about-us",
  data() {
    return {
      speciality: "",
      name: null,
      email: null,
      image: require("@/assets/img/realisations/dubai-hotel.jpg"),
      aboutTeam: {
        aboutTeam2: require("@/assets/img/faces/xmahieu.jpg"),
        aboutTeam3: require("@/assets/img/faces/lcharpentier2.jpg")
      },
      aboutOffice: {
        aboutOffice1: require("@/assets/img/examples/office2.jpg"),
        aboutOffice2: require("@/assets/img/examples/office4.jpg"),
        aboutOffice3: require("@/assets/img/examples/office3.jpg"),
        aboutOffice4: require("@/assets/img/examples/office5.jpg"),
        aboutOffice5: require("@/assets/img/examples/office1.jpg")
      }
    };
  },
  created() {
    AOS.init();
  }
};
</script>

<style lang="scss" scoped>
.mt-2 {
  margin-top: 1.5rem;
}
</style>
