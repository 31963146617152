<template>
  <div class="wrapper">
    <!-- Testimonials 1 -->
    <div
      class="section section-testimonials-1 section-image"
      :style="sectionTestimonials(testimonials1)"
    >
      <div class="container">
        <div class="md-layout">
          <div
            class="md-layout-item md-size-50 md-small-size-100 mx-auto text-center"
          >
            <h2 class="title">
              Ces clients <br> Ont confiances en nous
            </h2>
            <h5 class="description">
              Vous pouvez contacter nos clients <br> afin de savoir ce qu'ils pensent de nous
            </h5>
          </div>
        </div>
        <div class="md-layout">
          <div
              data-aos="fade-right"
              data-aos-easing="mk-easing"
              data-aos-duration="750"
              data-aos-delay="500"
              class="md-layout-item md-size-33 md-small-size-100">
            <testimonial-card>
              <md-icon slot="icon">
                format_quote
              </md-icon>
              <template slot="cardContent">
                <h5 class="card-description">
                  "Your products, all the kits that I have downloaded from your
                  site and worked with are sooo cool! I love the color mixtures,
                  cards... everything. Keep up the great work!"
                </h5>
              </template>
              <template slot="cardAction">
                <h4 class="card-title">
                  Alec Thompson
                </h4>
                <h6 class="card-category">
                  @alecthompson
                </h6>
                <div class="md-card-avatar">
                  <a href="javascript:void(0)">
                    <img
                      class="img"
                      :src="sectionTestimonials1.testimonials1"
                    >
                  </a>
                </div>
              </template>
            </testimonial-card>
          </div>
          <div
              data-aos="fade-down"
              data-aos-easing="mk-easing"
              data-aos-duration="750"
              class="md-layout-item md-size-33 md-small-size-100">
            <testimonial-card>
              <md-icon slot="icon">
                format_quote
              </md-icon>
              <template slot="cardContent">
                <h5 class="card-description">
                  "Don't be scared of the truth because we need to restart the
                  human foundation in truth. That's why I love you like Kanye
                  loves Kanye I love Rick Owens’ bed design but the back is not
                  so attractive"
                </h5>
              </template>
              <template slot="cardAction">
                <h4 class="card-title">
                  Gina Andrew
                </h4>
                <h6 class="card-category">
                  @ginaandrew
                </h6>
                <div class="md-card-avatar">
                  <a href="javascript:void(0)">
                    <img
                      class="img"
                      :src="sectionTestimonials1.testimonials2"
                    >
                  </a>
                </div>
              </template>
            </testimonial-card>
          </div>
          <div
              data-aos="fade-up-right"
              data-aos-easing="mk-easing"
              data-aos-duration="750"
              data-aos-delay="500"
              class="md-layout-item md-size-33 md-small-size-100">
            <testimonial-card>
              <md-icon slot="icon">
                format_quote
              </md-icon>
              <template slot="cardContent">
                <h5 class="card-description">
                  Your products, all the kits that I have downloaded from your
                  site and worked with are sooo cool! I love the color mixtures,
                  cards... everything. Keep up the great work!
                </h5>
              </template>
              <template slot="cardAction">
                <h4 class="card-title">
                  George West
                </h4>
                <h6 class="card-category">
                  @georgewest
                </h6>
                <div class="md-card-avatar">
                  <a href="javascript:void(0)">
                    <img
                      class="img"
                      :src="sectionTestimonials1.testimonials3"
                    >
                  </a>
                </div>
              </template>
            </testimonial-card>
          </div>
        </div>
      </div>
    </div>
    <!-- end Testimonials 1 -->
  </div>
</template>

<script>
import { TestimonialCard } from "@/components"
import AOS from "aos";
import "aos/dist/aos.css";

export default {
  name: "Testimonials",
  components: {
    TestimonialCard
  },
  data() {
    return {
      testimonials1: require("@/assets/img/dg2.jpg"),
      sectionTestimonials1: {
        testimonials1: require("@/assets/img/faces/card-profile1-square.jpg"),
        testimonials2: require("@/assets/img/faces/card-profile4-square.jpg"),
        testimonials3: require("@/assets/img/faces/card-profile2-square.jpg")
      },
      sectionTestimonials2: {
        testimonials1: require("@/assets/img/faces/kendall.jpg"),
        testimonials2: require("@/assets/img/faces/christian.jpg")
      },
      sectionTestimonials3: {
        testimonials1: require("@/assets/img/faces/card-profile6-square.jpg")
      }
    };
  },
  methods: {
    sectionTestimonials(image) {
      return {
        backgroundImage: `url(${image})`
      };
    }
  },
  created() {
    AOS.init();
  }
};
</script>

<style lang="scss">
.mt-0 {
  margin-top: 0 !important;
}
</style>
