<template>
  <div class="wrapper">
    <div class="pt-0 pb-0">
      <el-carousel
          indicator-position="none"
          class="no-border no-height"
          trigger="click"
          :interval="5000"
          v-on:change="slideListener($event)"
      >
        <el-carousel-item>
          <div
              class="page-header header-filter"
              :style="header('1')"
          >
            <div class="container">
              <div class="md-layout">
                <div
                    class="md-layout-item md-size-66 md-small-size-100 text-left"
                >

                </div>
              </div>
            </div>
          </div>
        </el-carousel-item>
        <el-carousel-item>
          <div
              class="page-header header-filter"
              :style="header('2')"
          >
            <div class="container">
              <div class="md-layout">
                <div
                    class="md-layout-item md-size-66 md-small-size-100 mx-auto text-center"
                >

                    <transition name="fadeOne">
                      <h1 class="title" v-show="showAnimation1" >
                        EXPERTISE
                      </h1>
                    </transition>
                  <div class="bgred">
                  </div>
                    <transition name="fadeTwo">
                      <div v-show="showAnimation1" class="container">
                        <p class="textAnimation2">France et International depuis 20 ans</p>
                      </div>
                    </transition>

                </div>
              </div>
            </div>
          </div>
        </el-carousel-item>
        <el-carousel-item>
          <div
              class="page-header header-filter"
              :style="header('3')"
          >
            <div class="container">
              <div class="md-layout">
                <div
                    class="md-layout-item md-size-66 md-small-size-100 ml-auto text-right"
                >
                  <transition name="fadeThree">
                    <h1 class="title" v-show="showAnimation2" >
                      CONFIANCE ET EXCELLENCE
                    </h1>
                  </transition>
                  <transition name="backGroundPosition2">
                    <div class="textAnimation6" v-show="showAnimation2">
                    </div>
                  </transition>
                  <transition name="fadeFour">
                    <h3 v-show="showAnimation2">
                      Au service de nos clients et partenaires
                    </h3>
                  </transition>
                </div>
              </div>
            </div>
          </div>
        </el-carousel-item>
        <el-carousel-item>
          <div
              class="page-header header-filter"
              :style="header('4')"
          >
            <div class="container">
              <div class="md-layout">
                <div
                    class="md-layout-item md-size-66 md-small-size-100 ml-auto text-right"
                >
                  <transition name="fadeFour">
                      <h1 class="textAnimation3 title"  v-show="showAnimation3" >
                        INVESTMENT MANAGEMENT
                      </h1>
                    </transition>

                  <transition name="backGroundPosition">
                    <div class="textAnimation7" v-show="showAnimation3">
                    </div>
                  </transition>

                    <transition name="slideFade">
                      <h3 class="textAnimation5" v-show="showAnimation3">
                        Sourcing, Management et co-investissement
                      </h3>
                    </transition>
                </div>
              </div>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <!-- end Headers 3 -->

    <div
      id="main-panel"
      class="main main-raised"
    >


      <!-- Introduction section -->
      <introduction />

      <!-- en Bref section -->
      <enBref />

      <!-- design section -->
      <design />


      <pre-footer-areas />
    </div>

  </div>
</template>

<script>
import introduction from "./abbaq/Introduction";
import EnBref from "./abbaq/EnBref";
import design from "./abbaq/Design";
import PreFooterAreas from "./abbaq/PreFooterSection";
import Mixins from "@/plugins/basicMixins";
import {isMobileOnly, isTablet} from "mobile-device-detect";

export default {
  name: "Accueil",
  components: {
    introduction,
    EnBref,
    design,
    PreFooterAreas
  },
  mixins: [Mixins.VerticalNav, Mixins.HeaderImage],
  bodyClass: "accueil-page",
  props: {
    image: {
      type: String,
      default: require("@/assets/img/vue-mk-header.jpg")
    },
    leaf4: {
      type: String,
      default: require("@/assets/img/leaf4.png")
    },
    leaf3: {
      type: String,
      default: require("@/assets/img/leaf3.png")
    },
    leaf2: {
      type: String,
      default: require("@/assets/img/leaf2.png")
    },
    leaf1: {
      type: String,
      default: require("@/assets/img/leaf1.png")
    },
    landing: {
      type: String,
      default: require("@/assets/img/landing.jpg")
    },
    profile: {
      type: String,
      default: require("@/assets/img/profile.jpg")
    }
  },
  data() {
    return {
      isComputer: false,
      isMobile: false,
      isTablet: false,
      showAnimation1: false,
      showAnimation2: false,
      showAnimation3: false,
      companyName: null,
      companyEmail: null,
      companyPassword: null,
      toggledClass: false,
      headerImages: {
        Image1: require("@/assets/img/Abbaq/image-accueil-1.jpg"),
        Image2: require("@/assets/img/Abbaq/image-accueil-2.jpg"),
        Image3: require("@/assets/img/Abbaq/image-accueil-3.jpg"),
        Image4: require("@/assets/img/Abbaq/image-accueil-4.jpg"),
        Image5: require("@/assets/img/Abbaq/abbaqimage3.jpg")
      },
      firstname: null,
      email: null,
      password: null,
      leafShow: false
    };
  },
  mounted() {
    this.leafActive();
    this.isMobile = isMobileOnly;
    this.isTablet = isTablet;
    this.isComputer = !this.isTablet && !this.isMobile;
    window.addEventListener("resize", this.leafActive);
    document.addEventListener("scroll", this.scrollListener);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.leafActive);
    document.removeEventListener("scroll", this.scrollListener);
  },
  methods: {
    leafActive() {
      if (window.innerWidth < 768) {
        this.leafShow = false;
      } else {
        this.leafShow = true;
      }
    },
    header(nr) {
      if(nr == 1 && this.isMobile) {
        nr=5;
      }
      return {
        backgroundImage: `url(${this.headerImages[`Image${nr}`]})`
      };
    },
    slideListener(e) {
      if(e==1) {
        this.showAnimation1 = true;
        this.showAnimation2 = false;
      } else if(e==2){
        this.showAnimation1 = false;
        this.showAnimation2 = true;
        this.showAnimation3 = false;
      } else if(e==3){
        this.showAnimation2 = false;
        this.showAnimation3 = true;
      } else {
        this.showAnimation1 = this.showAnimation2 = this.showAnimation3 = false;
      }
    }
  }
};
</script>
<style lang="scss">
.section-download {
  .md-button + .md-button {
    margin-left: 5px;
  }
}

@media all and (min-width: 991px) {
  .btn-container {
    display: flex;
  }
}
@media all and (max-width: 768px) {
  .vertical-nav-active {
    display: none;
  }
}

.mb-0 {
  padding-bottom: 0 !important;
}

#morphing-cards {
  padding-top: 70px;
}
</style>
