<template>
  <div class="section section-components pt-0">
    <div class="container">
      <div class="md-layout">
        <div class="md-layout-item md-size-33 md-small-size-100">
          <h3 class="title-en">
            Strategy
          </h3>
          <h5 class="descriptionen">
            Active investor and property developer.
          </h5>
        </div>
        <div class="md-layout-item md-size-66 md-small-size-100 mx-auto">

          <h5 class="description">
            ABBAQ focuses on real estate opportunities that are currently
            distressed or undervalued and in green-field projects, identifying growth
            potential in partnership  with local players. As ABBAQ believes in
            diversified exposure and alignment of interests, it teams up with other
            investors and local professionals. <br><br>It then project-manages the investments
            for the benefits of all investors and acts in collaboration with local or pure
            players in the execution of the projects.
          </h5>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Components",
  data() {
    return {
      sectionComponents: {
        img1: require("@/assets/img/Abbaq/site-construction.jpg"),
        img2: require("@/assets/img/Abbaq/investissement1.jpg"),
        img3: require("@/assets/img/Abbaq/investissement2.jpg"),
        img4: require("@/assets/img/Abbaq/investissement3.png"),
        img5: require("@/assets/img/Abbaq/plan-architect.jpg"),
        img6: require("@/assets/img/Abbaq/reference.jpg")
      }
    };
  }
};
</script>

<style lang="css"></style>
