<template>
  <div class="wrapper">
    <div id="cards">
      <template v-if="!sectionCardPlain && !sectionMorphingCards">
        <!-- Blog Cards -->
        <div class="md-layout">
          <div class="md-layout-item md-large-size-33 md-small-size-100">
            <blog-card
              :shadow-normal="false"
              :no-colored-shadow="false"
              :card-image="abbaq.abbaqRea4"
              :card-title="imageTitleHeader.titleRea1"
            >
              <template
                slot="cardTitleHeader"
              >
                Pascal Towers
              </template>
              <template slot="cardContent">
                <h6 class="card-category text-info">
                   La Défense, Paris
                </h6>
                <p class="card-description">
                  Les deux tours Pascal à La Défense, le quartier des affaires de Paris, ont été achetées à un prince saoudien.
                  Les principaux locataires des 70 000 m² de bureaux étaient le ministère de l’Environnement et la SNCF, opérateur ferroviaire français.
                  Les tours ont été restructurées et les contrats ont été prolongés pour une durée beaucoup plus longue.
                  Les tours ont ensuite été vendues à une banque d'investissement américaine.
                  <!--Both Pascal office towers in La Défense, Paris’s business district, were purchased from a Saudi Prince.
                  The main tenants for the 70,000 sqm of office space were the French ministry of the environment and
                  SNCF, France’s railway operator. The towers were restructured and the contracts extended for
                  significantly longer maturity. The towers were subsequently sold to a leading US investment bank.-->
                </p>
              </template>
            </blog-card>
            <md-card :style="bgFullCards(abbaq.abbaqRea1)">
              <md-card-content>
                <a
                    @click="imageModal(abbaq.abbaqRea1)"
                    href="javascript:void(0)"
                >
                <h5 class="card-category card-category-social">
                  <br>
                  <br>
                  <br>
                  <br>
                  <br>
                </h5>
                </a>
              </md-card-content>
            </md-card>
            <md-card :style="bgFullCards(abbaq.abbaqRea2)">
              <md-card-content>
                <a
                    @click="imageModal(abbaq.abbaqRea2)"
                    href="javascript:void(0)"
                >
                <h5 class="card-category card-category-social">
                  <br>
                  <br>
                  <br>
                  <br>
                  <br>
                </h5>
                </a>
              </md-card-content>
            </md-card>
            <blog-card
                :shadow-normal="false"
                :no-colored-shadow="false"
                :card-image="abbaq.abbaqRea16"
                :card-title="imageTitleHeader.titleRea2"
            >
              <template
                  slot="cardTitleHeader"
              >
                Golden Visa Apartments
              </template>
              <template slot="cardContent">

                <h6 class="card-category text-danger">
                  Madrid
                </h6>

                <p class="card-description">
                  Au total, quatre bâtiments représentant environ 4800 m2 ont été restructurés ou entièrement reconstruits
                  en 36 appartements de luxe à proximité immédiate de la Plaza Major. Tous les appartements ont été vendus à
                  Investisseurs asiatiques dans le cadre du programme espagnol "golden visa".
                  <!-- A total of four buildings representing approximately 4,800 sqm were restructured or entirely rebuilt
                  into 36 luxury apartments in the immediate vicinity of the Plaza Major. All apartments were sold to
                  Asian investors as part of the Spanish golden visa program.-->
                </p>
              </template>
            </blog-card>

            <md-card :style="bgFullCards(abbaq.abbaqRea19)">
              <md-card-content>
                <a
                    @click="imageModal(abbaq.abbaqRea19)"
                    href="javascript:void(0)"
                >
                  <h5 class="card-category card-category-social">
                    <br>
                    <br>
                    <br>
                    <br>
                    <br>
                  </h5>
                </a>
              </md-card-content>
            </md-card>

            <md-card :style="bgFullCards(abbaq.abbaqRea20)">
              <md-card-content>
                <a
                    @click="imageModal(abbaq.abbaqRea20)"
                    href="javascript:void(0)"
                >
                  <h5 class="card-category card-category-social">
                    <br>
                    <br>
                    <br>
                    <br>
                    <br>
                  </h5>
                </a>
              </md-card-content>
            </md-card>
          </div>
          <div
            class="md-layout-item md-large-size-33 md-medium-50 md-small-size-100"
          >
            <md-card :style="bgFullCards(abbaq.abbaqRea3)">
              <md-card-content>
                <a
                    @click="imageModal(abbaq.abbaqRea3)"
                    href="javascript:void(0)"
                >
                <h5 class="card-category card-category-social">
                  <br>
                  <br>
                  <br>
                  <br>
                  <br>
                </h5>
                </a>
              </md-card-content>
            </md-card>
            <blog-card
              :shadow-normal="false"
              :no-colored-shadow="false"
              :card-image="abbaq.abbaqRea5"
              :card-title="imageTitleHeader.titleRea3"
            >
              <template
                  slot="cardTitleHeader"
              >
                Sheraton San Siro
              </template>
              <template slot="cardContent">
                <h6 class="card-category text-success">
                  Milan
                </h6>
                <h4 class="card-title">
                  Acquisition d'un hôtel de <br>35000 m2
                  <!--Acquisition of a 35,000 sqm hotel-->
                </h4>
                <p class="card-description">
                  D'une grande institution financière italienne dans le cadre d'une procédure de faillite.
                  <!--From a large Italian financial institution in the context of a
                  bankruptcy procedure.-->
                  <a
                      @click="imageModal(abbaq.abbaqRea6, 'Sheraton San Siro Plan')"
                      href="javascript:void(0)"
                  >
                  <img :src="abbaq.abbaqRea6" />
                  </a>
                  L'hôtel Sheraton de 315 chambres a été complètement restructuré sur une période de trois ans et rebaptisé Sheraton, une marque de Marriott International, Inc.
                  <!--The 315 room Sheraton hotel was completely restructured over a three-year
                  period and rebranded as a Sheraton, a brand of Marriott International, Inc.-->
                </p>
              </template>
            </blog-card>
            <md-card :style="bgFullCards(abbaq.abbaqRea7)">
              <md-card-content>
                <a
                    @click="imageModal(abbaq.abbaqRea7)"
                    href="javascript:void(0)"
                >
                  <h5 class="card-category card-category-social">
                    <br>
                    <br>
                    <br>
                    <br>
                    <br>
                  </h5>
                </a>
              </md-card-content>
            </md-card>
            <blog-card
                :shadow-normal="false"
                :no-colored-shadow="false"
                :card-image="abbaq.abbaqRea21"
                :card-title="imageTitleHeader.titleRea4"
            >
              <template
                  slot="cardTitleHeader"
              >
                Ericsson Headquarters
              </template>
              <template slot="cardContent">

                <h6 class="card-category text-danger">
                  Roma
                </h6>

                <p class="card-description">
                  10 immeubles de la périphérie de Rome représentant plus de 10 000 m² de bureaux ont été
                  achetés à un groupe d'investisseurs italiens. Le bâtiment abritait le siège d’Ericsson
                  pour l’Europe du Sud. Le contrat a été prolongé pour une durée beaucoup plus longue,
                  une grande partie du terrain a été rezonée à des fins résidentielles générant une valeur substantielle.
                  La propriété a ensuite été vendue à un grand investisseur institutionnel italien.
                  <!--10 buildings in the outskirts of Rome representing more than 10,000 sqm of office space were
                  purchased from a group of Italian investors. The building housed Ericsson’s Headquarters for southern
                  Europe. The contract was extended for a much longer duration, a large part of the land was rezoned
                  for residential use generating substantial value. The property was then sold to a large Italian
                  institutional investor.-->
                </p>
              </template>
            </blog-card>
            <md-card :style="bgFullCards(abbaq.abbaqRea18)">
              <md-card-content>
                <a
                    @click="imageModal(abbaq.abbaqRea18)"
                    href="javascript:void(0)"
                >
                  <h5 class="card-category card-category-social">
                    <br>
                    <br>
                    <br>
                    <br>
                    <br>
                  </h5>
                </a>
              </md-card-content>
            </md-card>
          </div>
          <div class="md-layout-item md-large-size-33 md-small-size-100">
            <md-card :style="bgFullCards(abbaq.abbaqRea8)">
              <md-card-content>
                <a
                    @click="imageModal(abbaq.abbaqRea8)"
                    href="javascript:void(0)"
                >
                  <h5 class="card-category card-category-social">
                    <br>
                    <br>
                    <br>
                    <br>
                    <br>
                  </h5>
                </a>
              </md-card-content>
            </md-card>
            <md-card :style="bgFullCards(abbaq.abbaqRea9)">
              <md-card-content>
                <a
                    @click="imageModal(abbaq.abbaqRea9)"
                    href="javascript:void(0)"
                >
                  <h5 class="card-category card-category-social">
                    <br>
                    <br>
                    <br>
                    <br>
                    <br>
                  </h5>
                </a>
              </md-card-content>
            </md-card>
            <md-card :style="bgFullCards(abbaq.abbaqRea10)">
              <md-card-content>
                <a
                    @click="imageModal(abbaq.abbaqRea10)"
                    href="javascript:void(0)"
                >
                  <h5 class="card-category card-category-social">
                    <br>
                    <br>
                    <br>
                    <br>
                    <br>
                  </h5>
                </a>
              </md-card-content>
            </md-card>
            <blog-card
              :shadow-normal="false"
              :no-colored-shadow="false"
              :card-image="abbaq.abbaqRea11"
              :card-title="imageTitleHeader.titleRea5"
            >
              <template
                  slot="cardTitleHeader"
              >
                Sheraton Malpensa
              </template>
              <template slot="cardContent">

                <h6 class="card-category text-danger">
                  Milan
                </h6>

                <h4 class="card-title">
                  The Sheraton Malpensa Hotel &amp; Conference center
                </h4>

                <p class="card-description">
                  est un hôtel 4 étoiles haut de gamme avec 437 chambres
                  et un centre de conférence de 30 chambres pouvant accueillir
                  jusqu'à 1100 participants.
                  <!--is a 4-star upscale hotel with 437 rooms and a 30
                  room conference center for up to 1100 participants.-->
                  <a
                      @click="imageModal(abbaq.abbaqRea13, 'The Sheraton Malpensa Hotel')"
                      href="javascript:void(0)"
                  >
                    <img :src="abbaq.abbaqRea13" width="40%" />
                  </a>
                  Nous avons pris une participation minoritaire dans la direction
                  de l'hôtel suite à une restructuration de la dette menée par nos partenaires.
                </p>
              </template>
            </blog-card>

            <md-card :style="bgFullCards(abbaq.abbaqRea15)">
              <md-card-content>
                <a
                    @click="imageModal(abbaq.abbaqRea15)"
                    href="javascript:void(0)"
                >
                  <h5 class="card-category card-category-social">
                    <br>
                    <br>
                    <br>
                    <br>
                    <br>
                  </h5>
                </a>
              </md-card-content>
            </md-card>

            <md-card :style="bgFullCards(abbaq.abbaqRea17)">
              <md-card-content>
                <a
                    @click="imageModal(abbaq.abbaqRea17)"
                    href="javascript:void(0)"
                >
                  <h5 class="card-category card-category-social">
                    <br>
                    <br>
                    <br>
                    <br>
                    <br>
                  </h5>
                </a>
              </md-card-content>
            </md-card>

          </div>
        </div>
        <!-- end Blog Cards -->

      </template>
    </div>
    <div
        class="modal modal-bg"
        :class="{ show: imageModalClassic }"
    >
      <modal
          v-if="imageModalClassic"
          @close="imageModalClassic = false"
      >
        <template slot="header">
          <h4 class="modal-title">
            {{ imageTitle }}
          </h4>
          <md-button
              class="md-simple md-close md-just-icon md-round modal-default-button"
              @click="imageModalClassic = false"
          >
            <md-icon>clear</md-icon>
          </md-button>
        </template>

        <template slot="body">

          <img :src="srcImageValue" width="100%" />
        </template>

        <template slot="footer">
          <md-button
              class="md-danger md-simple"
              @click="imageModalClassic = false"
          >
            Close
          </md-button>
        </template>
      </modal>
    </div>
  </div>
</template>

<script>
import {
  BlogCard,
    Modal,
} from "@/components";

export default {
  components: {
    BlogCard,
    Modal
  },
  props: {
    sectionCardPlain: Boolean,
    sectionMorphingCards: Boolean
  },
  methods: {
    imageModal(imageSrc, imageTitle) {
        this.imageTitle = imageTitle;
        this.srcImageValue = imageSrc;
        this.imageModalClassic = true;
    },
    bgFullCards(cardImage) {
      return {
        backgroundSize: '100% 100%',
        backgroundImage: `url(${cardImage})`
      };
    }
  },
  data() {
    return {
      imageTitle:null,
      srcImageValue: null,
      imageModalClassic: false,
      imageTitleHeader: {
        titleRea1: 'Pascal office towers in La Défense',
        titleRea2: 'Golden Visa Apartments, Madrid',
        titleRea3: 'Sheraton San Siro, Milan',
        titleRea4: 'Ericson Headquarters',
        titleRea5: 'The Sheraton Malpensa Hotel & Conference center',
      },
      abbaq: {
        abbaqRea1: require("@/assets/img/Abbaq/realisation_abbaq/image1.jpg"),
        abbaqRea2: require("@/assets/img/Abbaq/realisation_abbaq/image2.png"),
        abbaqRea3: require("@/assets/img/Abbaq/realisation_abbaq/image3.jpg"),
        abbaqRea4: require("@/assets/img/Abbaq/realisation_abbaq/image4.jpg"),
        abbaqRea5: require("@/assets/img/Abbaq/realisation_abbaq/image5.jpg"),
        abbaqRea6: require("@/assets/img/Abbaq/realisation_abbaq/image6.jpg"),
        abbaqRea7: require("@/assets/img/Abbaq/realisation_abbaq/image7.png"),
        abbaqRea8: require("@/assets/img/Abbaq/realisation_abbaq/image8.jpg"),
        abbaqRea9: require("@/assets/img/Abbaq/realisation_abbaq/image9.png"),
        abbaqRea10: require("@/assets/img/Abbaq/realisation_abbaq/image11.png"),
        abbaqRea11: require("@/assets/img/Abbaq/realisation_abbaq/image12.png"),
        abbaqRea13: require("@/assets/img/Abbaq/realisation_abbaq/image15.png"),
        abbaqRea14: require("@/assets/img/Abbaq/realisation_abbaq/image16.png"),
        abbaqRea15: require("@/assets/img/Abbaq/realisation_abbaq/image17.png"),
        abbaqRea16: require("@/assets/img/Abbaq/realisation_abbaq/image18.png"),
        abbaqRea17: require("@/assets/img/Abbaq/realisation_abbaq/image19.png"),
        abbaqRea18: require("@/assets/img/Abbaq/realisation_abbaq/image20.jpg"),
        abbaqRea19: require("@/assets/img/Abbaq/realisation_abbaq/image21.jpg"),
        abbaqRea20: require("@/assets/img/Abbaq/realisation_abbaq/image22.jpg"),
        abbaqRea21: require("@/assets/img/Abbaq/realisation_abbaq/image23.jpg")
      }
    };
  }
};
</script>
