<template>
  <div class="section section-cards section-dark">
    <div class="container">
      <div class="md-layout">
        <div class="md-layout-item md-size-60 md-small-size-100">
          <div class="image-container">
            <img
              class="img"
              :src="sectionCards.img1"
            >
          </div>
        </div>
        <div class="md-layout-item md-size-33 md-small-size-100 ml-auto">
          <div class="section-description">
            <h3 class="title">
              Stratégie
            </h3>
            <h6 class="description">
              Développe et Repositionne
            </h6>
            <h5 class="description">
              ABBAQ se concentre sur des opportunités immobilières à forte valeur ajoutée. ABBAQ sait
              identifier les opérations avec un fort potentiel de croissance.<br><br>
              Croyant en une exposition diversifiée et à la convergence d’intérêts, ABBAQ crée des
              partenariats avec d’autres investisseurs. ABBAQ gère ensuite les projets au profit de tous et
              agit en collaboration avec des acteurs locaux dans l&#39;exécution des projets.
            </h5>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Cards",
  data() {
    return {
      sectionCards: {
        img1: require("@/assets/img/Abbaq/realisation-cards.png")
      }
    };
  }
};
</script>

<style lang="css"></style>
