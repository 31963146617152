<template>
  <div class="section section-components pt-0">
    <div class="container">
      <div class="md-layout">
        <div class="md-layout-item md-size-33 md-small-size-100">
          <h3 class="title-en">
            Projects
          </h3>
          <h5 class="descriptionen">
            Our Principals were instrumental in the following projects
          </h5>
        </div>
        <div class="md-layout-item md-size-66 md-small-size-100 mx-auto">

          <h5 class="description">
            Abbaq develops projects in real estate across the board with particular
            expertise in hospitality and residential real estate investments. <br>
            From repositioning real estate assets to greenfield projects, Abbaq seeks alternative property developments to generate value.<br><br>
            With projects spanning from 2’000 sqm to 60’000 sqm, Abbaq delivers risk adjusted returns. <br><br>
            We focus on mid-sized (USD 20 to 100 M) properties and target an annualized return of 12% to 25% through execution
            of value-add programs. Our experience in asset management allows us to seek opportunities that result from both
            local and macro considerations.
          </h5>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Components",
  data() {
    return {
      sectionComponents: {
      }
    };
  }
};
</script>

<style lang="css"></style>
