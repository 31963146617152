<template>
  <div class=" wrapper">
    <parallax
      class="page-header header-filter header-small"
      parallax-active="true"
      :style="headerStyle"
    >
      <div class="container">
        <div class="md-layout">
          <div
            class="md-layout-item md-size-66 md-small-size-100 mx-auto text-center"
          >
            <h1 class="title">
              Strategy
            </h1>
            <h4>
              Hands-on approach for successful real estate developments
            </h4>
          </div>
        </div>
      </div>
    </parallax>
    <div class="main main-raised">
      <div class="section section-about-description text-center pb-0">
        <div class="container">
          <div class="md-layout">
            <div class="md-layout-item md-size-66 md-small-size-100 mx-auto">
              <h5 class="description">
                Abbaq is an active investor and property developer. It focuses on real estate opportunities that are currently distressed or undervalued and in green-field projects, identifying growth potential in partnership with local players. As Abbaq believes in diversified exposure and alignment of interests, it teams up with other investors and local professionals. It then project-manages the investments for the benefits of all investors and acts in collaboration with local or pure players in the execution of the projects. <br> <br>

                The Company focuses on small to medium size projects with an emphasis on added-value projects in markets where debt finance and equity finance are distorted or scarce for local players. Abbaq has an hands-on approach to building successful real estate developments that provide attractive risk-adjusted returns in growth markets.

              </h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AOS from "aos";
import "aos/dist/aos.css";
import Mixins from "@/plugins/basicMixins";

export default {
  components: {
  },
  mixins: [Mixins.HeaderImage],
  bodyClass: "about-us",
  data() {
    return {
      speciality: "",
      name: null,
      email: null,
      image: require("@/assets/img/realisations/milan.png"),
      aboutTeam: {
        aboutTeam2: require("@/assets/img/faces/xmahieu.jpg"),
        aboutTeam3: require("@/assets/img/faces/lcharpentier2.jpg")
      },
      aboutOffice: {
        aboutOffice1: require("@/assets/img/examples/office2.jpg"),
        aboutOffice2: require("@/assets/img/examples/office4.jpg"),
        aboutOffice3: require("@/assets/img/examples/office3.jpg"),
        aboutOffice4: require("@/assets/img/examples/office5.jpg"),
        aboutOffice5: require("@/assets/img/examples/office1.jpg")
      }
    };
  },
  created() {
    AOS.init();
  }
};
</script>

<style lang="scss" scoped>
.mt-2 {
  margin-top: 1.5rem;
}
</style>
