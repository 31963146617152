var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"section-dark"},[_c('div',{directives:[{name:"prlx",rawName:"v-prlx.background",value:({
            direction: 'x',
            speed: 0.1,
            fromBottom: true,
          }),expression:"{\n            direction: 'x',\n            speed: 0.1,\n            fromBottom: true,\n          }",modifiers:{"background":true}}],staticClass:"section bacgroundImage",style:(_vm.setBG(_vm.sectionScroll.img1))},[_vm._m(0)]),_c('div',{directives:[{name:"prlx",rawName:"v-prlx.background",value:({
            direction: 'y',
            speed: 0.1,
            fromBottom: true,
          }),expression:"{\n            direction: 'y',\n            speed: 0.1,\n            fromBottom: true,\n          }",modifiers:{"background":true}}],staticClass:"section bacgroundImage",style:(_vm.setBG(_vm.sectionScroll.img2))},[_vm._m(1)]),_c('div',{directives:[{name:"prlx",rawName:"v-prlx.background",value:({
            direction: 'x',
            preserveInitialPosition: true,
            speed: 0.1,
            fromBottom: true,
          }),expression:"{\n            direction: 'x',\n            preserveInitialPosition: true,\n            speed: 0.1,\n            fromBottom: true,\n          }",modifiers:{"background":true}}],staticClass:"section bacgroundImage backgroundScrollingPosition1",style:(_vm.setBG(_vm.sectionScroll.img3))},[_vm._m(2)]),_c('div',{directives:[{name:"prlx",rawName:"v-prlx.background",value:({
            direction: 'y',
            speed: 0.1,
            fromBottom: true,
          }),expression:"{\n            direction: 'y',\n            speed: 0.1,\n            fromBottom: true,\n          }",modifiers:{"background":true}}],staticClass:"section bacgroundImage",style:(_vm.setBG(_vm.sectionScroll.img4))},[_vm._m(3)]),_c('div',{directives:[{name:"prlx",rawName:"v-prlx.background",value:({
            direction: 'x',
            speed: 0.1,
            fromBottom: true,
          }),expression:"{\n            direction: 'x',\n            speed: 0.1,\n            fromBottom: true,\n          }",modifiers:{"background":true}}],staticClass:"section bacgroundImage",style:(_vm.setBG(_vm.sectionScroll.img5))},[_vm._m(4)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"textScrollingLeft"},[_vm._v(" Out of the box real estate investments ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"textScrollingCenter"},[_vm._v(" In large European cities ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"textScrollingRight"},[_vm._v(" Residential buildings ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"textScrollingCenter"},[_vm._v(" Hospitality ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"textScrollingLeft"},[_vm._v(" Office buildings ")])])}]

export { render, staticRenderFns }