<template>
  <div class=" wrapper">
    <parallax
        class="page-header header-filter header-small"
        parallax-active="true"
        :style="headerStyle"
    >
      <div class="container">
        <div class="md-layout">
          <div
              class="md-layout-item md-size-66 md-small-size-100 mx-auto text-center"
          >
            <h1 class="title">
              Nos Réalisations
            </h1>
            <h4>

            </h4>
          </div>
        </div>
      </div>
    </parallax>
    <div class="main main-raised">
      <div class="cd-section">
        <div
            id="cards"
            class="section section-gray pb-0"
        >
          <div class="container">
            <CardsSection />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AOS from "aos";
import "aos/dist/aos.css";
import Mixins from "@/plugins/basicMixins";
import CardsSection from "./abbaq/CardsSection";

export default {
  components: {
    CardsSection
  },
  mixins: [Mixins.HeaderImage],
  bodyClass: "nos-projets",
  data() {
    return {
      speciality: "",
      name: null,
      email: null,
      image: require("@/assets/img/realisations/milan.png"),
      aboutTeam: {
        aboutTeam1: require("@/assets/img/faces/marc.jpg"),
        aboutTeam2: require("@/assets/img/faces/kendall.jpg"),
        aboutTeam3: require("@/assets/img/faces/christian.jpg"),
        aboutTeam4: require("@/assets/img/faces/avatar.jpg")
      },
      aboutOffice: {
        aboutOffice1: require("@/assets/img/examples/office2.jpg"),
        aboutOffice2: require("@/assets/img/examples/office4.jpg"),
        aboutOffice3: require("@/assets/img/examples/office3.jpg"),
        aboutOffice4: require("@/assets/img/examples/office5.jpg"),
        aboutOffice5: require("@/assets/img/examples/office1.jpg")
      }
    };
  },
  created() {
    AOS.init();
  }
};
</script>

<style lang="scss" scoped>
.mt-2 {
  margin-top: 1.5rem;
}
</style>
