<template xmlns:src="http://www.w3.org/1999/xhtml">
  <div class="wrapper">
    <div id="pre-footer-areas">
      <div class="container">
        <!-- Simple Subscribe Line -->
          <div data-v-78fb57b9="" class="md-layout-item md-size-33 md-xsmall-hide md-medium-size-10"></div>
          <div
              class="md-layout-item contact-card md-large-size-25 md-xsmall-size-100 md-medium-size-75"
          >
            <rotating-card
                card-class="md-card-background"
                layer-background
                :front-card-image="cardRotating.cardRotatingBg1"
                :back-card-image="cardRotating.cardRotatingBg2"
                v-if="isComputer"
            >
              <template slot="frontRotateCardContent">
                <a href="javascript:void(0)">
                  <br>&nbsp;
                  <br>&nbsp;
                  <br>&nbsp;
                  <br>&nbsp;
                  <br>
                  <br>
                  <br>
                  <br>
                </a>
              </template>
              <template slot="backRotateCardContent">
                <div class="md-layout">
                  <div class="md-layout-item md-size-20">
                    <img :src="cardRotating.logo"  />
                  </div>
                  <div class="md-layout-item">
                    <h5 class="contact-title-card" style="color: black">
                      Building the future
                    </h5>
                  </div>
                </div>

                <div class="md-layout">
                  <div class="md-layout-item">
                    <p class="card-description border-left-black md-size-60">
                      42 AVENUE MONTAIGNE<br>
                      75008 PARIS<br>
                      France
                      <br>
                    </p>
                  </div>
                  <div class="md-layout-item email-card md-size-20">
                    <md-button
                        href="mailto:xmahieu@abbaq.fr"
                        class="md-danger md-just-icon md-round"
                    >
                      <md-icon>email</md-icon>
                    </md-button>
                  </div>
                </div>
              </template>
            </rotating-card>
            <rotating-card
                card-class="md-card-background"
                layer-background
                :manual-rotate-state="manualRotateState"
                manual-rotate
                :front-card-image="cardRotating.cardRotatingBg1"
                :back-card-image="cardRotating.cardRotatingBg2"
                v-if="isTablet"
            >
              <template slot="frontRotateCardContent">
                <br>&nbsp;
                <br>&nbsp;
                <br>&nbsp;
                <br>&nbsp;
                <br>&nbsp;
                <div class="stats">
                  <md-button
                      href="javascript:void(0)"
                      class="md-simple md-round"
                      @click="manualRotateState = true"
                  >
                    <md-icon>refresh</md-icon> Retourner
                  </md-button>
                </div>
              </template>
              <template slot="backRotateCardContent">
                <h5 class="card-title">
                  Build A Better Future
                </h5>
                <p class="card-description">
                  Contacter Nous...<br>

                  <md-button v-clipboard:copy="+33688888888">+33 6 88 88 88 88</md-button>
                </p>
                <div class="stats text-center">
                  <md-button
                      href="mailto:xavier@abbaq.fr"
                      class="md-twitter md-just-icon md-round"
                  >
                    <i class="fa fa-envelope" />
                  </md-button>
                  <md-button
                      href="tel: +336 88 88 88 88"
                      class="md-dribbble md-just-icon md-round"
                  >
                    <i class="fa fa-mobile" />
                  </md-button>

                </div>
                <br>
                <md-button
                    href="javascript:void(0)"
                    class="md-simple md-round"
                    @click="manualRotateState = false"
                >
                  <md-icon>refresh</md-icon> Revenir...
                </md-button>
              </template>
            </rotating-card>

            <rotating-card
                card-class="md-card-background"
                layer-background
                :manual-rotate-state="manualRotateState"
                manual-rotate
                :front-card-image="cardRotating.cardRotatingBg3"
                :back-card-image="cardRotating.cardRotatingBg2"
                v-if="isMobile"
            >
              <template slot="frontRotateCardContent">
                <br>&nbsp;
                <br>&nbsp;
                <br>&nbsp;
                <br>&nbsp;
                <br>&nbsp;
                <br>
                <div class="stats text-center">
                  <md-button
                      href="javascript:void(0)"
                      class="md-simple md-round"
                      @click="manualRotateState = true"
                  >
                    <md-icon>refresh</md-icon>retourner
                  </md-button>
                </div>
              </template>
              <template slot="backRotateCardContent">
                <h5 class="card-title">
                  Build a Better Future
                </h5>
                <p class="card-description">
                  Contacter nous...<br>

                  <md-button v-clipboard:copy="+33688888888">+33 6 88 88 88 88</md-button>
                </p>
                <div class="stats text-center">
                  <md-button
                      href="mailto:xavier@abbaq.fr"
                      class="md-twitter md-just-icon md-round"
                  >
                    <i class="fa fa-envelope" />
                  </md-button>
                  <md-button
                      href="tel: +336 88 88 88 88"
                      class="md-dribbble md-just-icon md-round"
                  >
                    <i class="fa fa-mobile" />
                  </md-button>

                </div>
                <br>
                <md-button
                    href="javascript:void(0)"
                    class="md-simple md-round"
                    @click="manualRotateState = false"
                >
                  <md-icon>refresh</md-icon> Revenir
                </md-button>
              </template>
            </rotating-card>
          </div>

          <div data-v-78fb57b9="" class="md-layout-item md-xsmall-hide  md-xsmall-hide md-medium-size-10"></div>
        </div>
    </div>
  </div>
</template>

<script>
import {
  RotatingCard
} from "@/components";
import {isMobileOnly, isTablet} from "mobile-device-detect";

export default {
  components: {
    RotatingCard
  },
  mounted() {
    this.isMobile = isMobileOnly;
    this.isTablet = isTablet;
    this.isComputer = !this.isTablet && !this.isMobile;
  },
  data() {
    return {
      isMobile: false,
      isTablet: false,
      isComputer: false,
      subscribe: null,
      manualRotateState: false,
      image: require("@/assets/img/bg7.jpg"),
      cardRotating: {
        cardRotatingBg1: require("@/assets/img/Abbaq/logo/logo-card-contact.jpg"),
        cardRotatingBg2: require("@/assets/img/Abbaq/logo/logo-card-contact-back.jpg"),
        logo: require("@/assets/img/Abbaq/logo/logo-abbaq.png"),
        cardRotatingBg3: require("@/assets/img/Abbaq/logo/logo-card-contact2.jpg"),
      }
    };
  },
  computed: {
    imageSubscribe() {
      return {
        backgroundImage: `url(${this.image})`
      };
    }
  }
};
</script>

<style lang="scss" scoped>
.d-flex-center {
  display: flex;
  align-items: center;
}
</style>
