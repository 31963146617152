<template>
  <div class=" wrapper">
    <parallax
      class="page-header header-filter header-small"
      parallax-active="true"
      :style="headerStyle"
    >
      <div class="container">
        <div class="md-layout">
          <div
            class="md-layout-item md-size-66 md-small-size-100 mx-auto text-center"
          >
            <h1 class="title">
              Projects
            </h1>
            <h4>
              Our Principals were instrumental in the following projects
            </h4>
          </div>
        </div>
      </div>
    </parallax>
    <div class="main main-raised">
      <div class="section section-about-description text-center pb-0">
        <div class="container">
          <div class="md-layout">
            <div class="md-layout-item md-size-66 md-small-size-100 mx-auto">
              <h5 class="description">
                Abbaq develops projects in real estate across the board with particular expertise in hospitality and residential real estate investments. From repositioning real estate assets to greenfield projects, Abbaq seeks alternative property developments to generate value. With projects spanning from 2’000 sqm to 60’000 sqm, Abbaq delivers risk adjusted returns. We focus on mid-sized (USD 20 to 100 M) properties and target an annualized return of 12% to 25% through execution of value-add programs. Our experience in asset management allows us to seek opportunities that result from both local and macro considerations.
              </h5>
            </div>
          </div>
        </div>
      </div>

      <ProjectPresentation />
    </div>
  </div>
</template>

<script>
import AOS from "aos";
import "aos/dist/aos.css";
import Mixins from "@/plugins/basicMixins";
import ProjectPresentation from "./abbaq/ProjectPresentation";

export default {
  components: {
    ProjectPresentation
  },
  mixins: [Mixins.HeaderImage],
  bodyClass: "about-us",
  data() {
    return {
      speciality: "",
      name: null,
      email: null,
      image: require("@/assets/img/realisations/la-defence-tours-Pascal-slide-1.png"),
      aboutTeam: {
        aboutTeam2: require("@/assets/img/faces/xmahieu.jpg"),
        aboutTeam3: require("@/assets/img/faces/lcharpentier2.jpg")
      },
      aboutOffice: {
        aboutOffice1: require("@/assets/img/examples/office2.jpg"),
        aboutOffice2: require("@/assets/img/examples/office4.jpg"),
        aboutOffice3: require("@/assets/img/examples/office3.jpg"),
        aboutOffice4: require("@/assets/img/examples/office5.jpg"),
        aboutOffice5: require("@/assets/img/examples/office1.jpg")
      }
    };
  },
  created() {
    AOS.init();
  }
};
</script>

<style lang="scss" scoped>
.mt-2 {
  margin-top: 1.5rem;
}
</style>
