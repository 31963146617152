<template>
  <div class="section">
    <div class="container">
      <div class="md-layout">
        <div class="md-layout-item md-medium-size-66 md-small-size-100 mx-auto">
          <h4 class="description text-center">
            ABBAQ est une société spécialisée en investissement et conseil immobilier. La société a été
            fondée en 2019 par Xavier Mahieu et Lionel Charpentier, tous deux professionnels dans les
            métiers de l’immobilier avec une expérience cumulée de plus de 40 ans.
          </h4>
        </div>
      </div>
      <!--
      <div class="features-1">
        <div class="md-layout">
          <div class="md-layout-item md-size-33 md-small-size-100">
            <info-areas
              text-center
              icon-color="rose"
              icon="import_contacts"
            >
              <h4
                slot="title"
                class="info-title"
              >
                Confident
              </h4>
              <div
                slot="content"
                class="description"
              >
                <p>
                  petit texte là
                </p>
              </div>
            </info-areas>
          </div>
          <div class="md-layout-item md-size-33 md-small-size-100">
            <info-areas
              text-center
              icon-color="info"
              icon="business"
            >
              <h4
                slot="title"
                class="info-title"
              >
                Strong
              </h4>
              <div
                slot="content"
                class="description"
              >
                <p>
                  petit texte là
                </p>
              </div>
            </info-areas>
          </div>
          <div class="md-layout-item md-size-33 md-small-size-100">
            <info-areas
              text-center
              icon-color="success"
              icon="fast_forward"
            >
              <h4
                slot="title"
                class="info-title"
              >
                Fast
              </h4>
              <div
                slot="content"
                class="description"
              >
                <p>
                  Autre petit texte là
                </p>
              </div>
            </info-areas>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import { InfoAreas } from "@/components";

export default {
  name: "Introduction",
  components: {

  }
};
</script>

<style lang="css"></style>
