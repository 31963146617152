<template>
  <div class=" wrapper">
    <parallax
      class="page-header header-filter header-small"
      parallax-active="true"
      :style="headerStyle"
    >
      <div class="container">
        <div class="md-layout">
          <div
            class="md-layout-item md-size-66 md-small-size-100 mx-auto text-center"
          >
            <h1 class="title">
              Qui sommes nous ?
            </h1>
            <h4>
              La relation que nous engageons avec <br>nos clients et nos partenaires est basée sur la confiance.
            </h4>
          </div>
        </div>
      </div>
    </parallax>
    <div class="main main-raised">
      <div class="section section-about-description text-center pb-0">
        <div class="container">
          <div class="md-layout">
            <div class="md-layout-item md-size-66 md-small-size-100 mx-auto">
              <h5 class="description">
                Nos membres fondateurs sont des entrepreneurs et des investisseurs bien établis dans l'immobilier et la finance structurée donnant à ABBAQ toutes les connaissances essentielles pour le développement immobilier, la finance, la gestion de projet et les stratégies de sortie. Notre expertise opérationnelle découle de notre implication dans la conceptualisation et le développement de projets ainsi que de notre rôle opérationnel critique dans la gestion de projets.
              </h5>
            </div>
          </div>
        </div>
      </div>
      <div class="section section-about-team section-team-1 pb-0">
        <div class="container">
          <div class="md-layout">
            <div class="md-layout-item md-size-50 md-small-size-100">
              <profile-card
                card-plain
                card-avatar
                data-aos="fade-up"
                data-aos-easing="mk-easing"
                data-aos-duration="750"
                data-aos-delay="500"
                :shadow-normal="false"
                :no-colored-shadow="false"
                :card-image="aboutTeam.aboutTeam2"
              >
                <template slot="cardContent">
                  <h4 class="card-title">
                    Xavier MAHIEU
                  </h4>
                  <h6 class="card-category text-muted">
                    co-fondateur
                  </h6>
                  <p class="card-description">
                    Xavier a, pendant plusieurs années, participé au développement national et européen du gestionnaire de fonds CBRE Investors, en réalisant de nombreuses acquisitions, locations et reventes de toutes typologies d’actifs.
                  </p>
                  <md-button
                      href="/xavier_mahieu"
                      class="md-warning md-round"
                  >
                    <md-icon>arrow_forward_ios</md-icon> Voir plus
                  </md-button>
                </template>
                <template slot="cardAction">
                  <md-button
                      href="mailto:xmahieu@abbaq.fr"
                      class="md-mail md-just-icon md-simple"
                  >
                    <i class="fa fa-envelope" />
                  </md-button>
                  <md-button
                      href="https://www.linkedin.com/in/xavier-mahieu-89299035/"
                      class="md-linkedin md-just-icon md-simple"
                  >
                    <i class="fab fa-linkedin" />
                  </md-button>
                </template>
              </profile-card>
            </div>
            <div class="md-layout-item md-size-50 md-small-size-100">
              <profile-card
                card-plain
                card-avatar

                data-aos="fade-up"
                data-aos-easing="mk-easing"
                data-aos-duration="750"
                data-aos-delay="800"
                :shadow-normal="false"
                :no-colored-shadow="false"
                :card-image="aboutTeam.aboutTeam3"
              >
                <template slot="cardContent">
                  <h4 class="card-title">
                    Lionel CHARPENTIER
                  </h4>
                  <h6 class="card-category text-muted">
                    CO-FONDATEUR
                  </h6>
                  <p class="card-description">
                    Lionel a joué un rôle déterminant dans de nombreuses transactions immobilières importantes exécutées par Apollo en Europe continentale.
                  </p>
                  <md-button
                      href="/lionel_charpentier"
                      class="md-info md-round"
                  >
                    <md-icon>arrow_forward_ios</md-icon> Voir plus
                  </md-button>
                </template>
                <template slot="cardAction">
                  <md-button
                      href="mailto:lcharpentier@abbaq.fr"
                      class="md-mail md-just-icon md-simple"
                  >
                    <i class="fa fa-envelope" />
                  </md-button>
                  <md-button
                      href="https://www.linkedin.com/in/charpentier-lionel-73a9356/"
                      class="md-linkedin md-just-icon md-simple"
                  >
                    <i class="fab fa-linkedin" />
                  </md-button>
                </template>
              </profile-card>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AOS from "aos";
import "aos/dist/aos.css";
import Testimonials from "./abbaq/Testimonials";
import { ProfileCard } from "@/components";
import Mixins from "@/plugins/basicMixins";

export default {
  components: {
    ProfileCard
  },
  mixins: [Mixins.HeaderImage],
  bodyClass: "about-us",
  data() {
    return {
      speciality: "",
      name: null,
      email: null,
      image: require("@/assets/img/Abbaq/header-aboutus2.jpg"),
      aboutTeam: {
        aboutTeam2: require("@/assets/img/faces/xmahieu.jpg"),
        aboutTeam3: require("@/assets/img/faces/lcharpentier2.jpg")
      },
      aboutOffice: {
        aboutOffice1: require("@/assets/img/examples/office2.jpg"),
        aboutOffice2: require("@/assets/img/examples/office4.jpg"),
        aboutOffice3: require("@/assets/img/examples/office3.jpg"),
        aboutOffice4: require("@/assets/img/examples/office5.jpg"),
        aboutOffice5: require("@/assets/img/examples/office1.jpg")
      }
    };
  },
  created() {
    AOS.init();
  }
};
</script>

<style lang="scss" scoped>
.mt-2 {
  margin-top: 1.5rem;
}
</style>
