<template>
  <div class="wrapper">
    <div
      id="contactUs2Map"
      class="map"
    />
    <div class="main main-raised">
      <div class="section section-contact">
        <div class="container">
          <div class="md-layout">
            <div class="md-layout-item md-size-50 md-small-size-100 ml-auto">
              <contact-card />
            </div>
            <div class="md-layout-item md-size-33 md-small-size-100 ml-auto">
              <info-areas
                class="pt-0"
                info-horizontal
                icon-color="danger"
                icon="pin_drop"
              >
                <h4
                  slot="title"
                  class="info-title"
                >
                  Où sommes nous
                </h4>
                <div
                  slot="content"
                  class="description"
                >
                  <p>

                    42 AVENUE MONTAIGNE,<br>
                    75008 PARIS<br>
                    France<br>
                    <md-button
                        class="md-info md-just-icon md-round"
                        v-clipboard:copy="address"
                    >
                      <md-icon>content_copy</md-icon>
                    </md-button>
                    <md-button
                        class="md-success md-just-icon md-round"
                        href="https://goo.gl/maps/fgBJCwqTmX4pKVrd6"
                    >
                      <i class="fab fa-google"></i>
                    </md-button>
                  </p>
                </div>
              </info-areas>
              <info-areas
                class="pt-0"
                info-horizontal
                icon-color="danger"
                icon="business_center"
              >
                <h4
                  slot="title"
                  class="info-title color-red-abbaq"
                >
                  Information Légale
                </h4>
                <div
                  slot="content"
                  class="description"
                >
                  <p>
                    ABBAQ. <br>
                    VAT · EN2341241 <br>
                    SIRET · 850 959 552 00017
                  </p>
                </div>
              </info-areas>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Mixins from "@/plugins/basicMixins";
import { InfoAreas } from "@/components";
import ContactCard from "./ContactCard";
export default {
  components: {
    ContactCard,
    InfoAreas
  },
  bodyClass: "contact-us",
  mixins: [Mixins.InitMap],
  data() {
    return {
      address: '42 AVENUE MONTAIGNE 75008 PARIS',
      name: null,
      email: null,
      phone: null,
      textarea: null
    };
  }
};
</script>

<style lang="scss" scoped>
.mt-3 {
  margin-top: 1.5rem;
}
</style>
