<template>
  <footer
    class="footer"
    :class="{
      [`footer-${type}`]: type,
      [`footer-${size}`]: size
    }"
    :data-background-color="backgroundColor"
  >
    <template
      v-if="$route.name !== 'ecommerce-page' && $route.name !== 'presentation'"
    >
      <div class="container">
        <nav>
          <ul>
            <li>
              <a href="/">
                Home
              </a>
            </li>
            <li>
              <a href="/about-us">
                About
              </a>
            </li>
            <li>
              <a href="/Strategy">
                Strategy
              </a>
            </li>
            <li>
              <a href="/Projects">
                Projects
              </a>
            </li>
            <li>
              <a href="/contact-us">
                Contact
              </a>
            </li>
          </ul>
        </nav>
        <div class="copyright">
          &copy; {{ year }}, Abbaq all Right reserved.
        </div>
      </div>
    </template>

  </footer>
</template>
<script>
export default {
  props: {
    backgroundColor: String,
    type: String,
    size: String
  },
  data() {
    return {
      year: new Date().getFullYear(),
      img1: require("@/assets/img/faces/card-profile6-square.jpg"),
      img2: require("@/assets/img/faces/christian.jpg"),
      img3: require("@/assets/img/faces/card-profile4-square.jpg"),
      img4: require("@/assets/img/faces/card-profile1-square.jpg"),
      img5: require("@/assets/img/faces/marc.jpg"),
      img6: require("@/assets/img/faces/kendall.jpg"),
      img7: require("@/assets/img/faces/card-profile5-square.jpg"),
      img8: require("@/assets/img/faces/card-profile2-square.jpg")
    };
  }
};
</script>
<style></style>
