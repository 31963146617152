<template xmlns:src="http://www.w3.org/1999/xhtml">
  <div class="wrapper">
    <div id="pre-footer-areas">
      <div class="container">
        <!-- Simple Subscribe Line -->
            <rotating-card
                card-class="md-card-background"
                layer-background
                :front-card-image="cardRotating.cardRotatingBg1"
                :back-card-image="cardRotating.cardRotatingBg2"
                v-if="isComputer"
            >
              <template slot="frontRotateCardContent">
                <a href="javascript:void(0)">
                  &nbsp;
                  <br>&nbsp;
                  <br>&nbsp;
                  <br>
                  <br>
                  <br>&nbsp;
                  <br>
                  <br>
                </a>
              </template>
              <template slot="backRotateCardContent">
                    <h5 class="contact-title-card" style="color: whitesmoke">
                      Build A Better Future
                    </h5>
                <br>

                <div class="md-layout">
                  <div class="md-layout-item">
                    <p class="card-description border-left-black md-size-60">
                      <i class="md-icon md-icon-font md-white">settings_cell</i>
                      <a href="tel:0668942385" class="md-white md-button"> : +33 6 68 94 23 85</a>
                      <br>
                      <br>

                      <i class="md-icon md-icon-font md-white">email</i>
                      <a href="mailto:xmahieu@abbaq.fr" class="md-white md-button"> : xmahieu@abbaq.fr</a>
                    </p>
                  </div>
                </div>


              </template>
            </rotating-card>

        <rotating-card
            card-class="md-card-background"
            layer-background
            :manual-rotate-state="manualRotateState"
            manual-rotate
            :front-card-image="cardRotating.cardRotatingBg1"
            :back-card-image="cardRotating.cardRotatingBg2"
            v-if="isTablet"
        >
          <template slot="frontRotateCardContent">
            <br>&nbsp;
            <br>&nbsp;
            <br>&nbsp;
            <br>&nbsp;
            <br>&nbsp;
            <br>
            <div class="stats text-center">
              <md-button
                  href="javascript:void(0)"
                  class="md-success md-round"
                  @click="manualRotateState = true"
              >
                <md-icon>refresh</md-icon>
              </md-button>
            </div>
          </template>
          <template slot="backRotateCardContent">
            <h5 class="card-title">
              Build A Better Future
            </h5>
            <p class="card-description">
              Contacter Nous...<br>

              <md-button v-clipboard:copy="+33688888888">+33 6 68 94 23 85</md-button>
            </p>
            <div class="stats text-center">
              <md-button
                  href="mailto:xmahieu@abbaq.fr"
                  class="md-twitter md-just-icon md-round"
              >
                <i class="fa fa-envelope" />
              </md-button>
              <md-button
                  href="tel: +33668942385"
                  class="md-dribbble md-just-icon md-round"
              >
                <i class="fa fa-mobile" />
              </md-button>

            </div>
            <br>
            <md-button
                href="javascript:void(0)"
                class="md-simple md-round"
                @click="manualRotateState = false"
            >
              <md-icon>refresh</md-icon> Back...
            </md-button>
          </template>
        </rotating-card>
        <rotating-card
            card-class="md-card-background"
            layer-background
            :manual-rotate-state="manualRotateState"
            manual-rotate
            :front-card-image="cardRotating.cardRotatingBg3"
            :back-card-image="cardRotating.cardRotatingBg1"
            v-if="isMobile"
        >
          <template slot="frontRotateCardContent">
            <br>&nbsp;
            <br>&nbsp;
            <br>&nbsp;
            <br>&nbsp;
            <br>&nbsp;
            <br>
            <div class="stats text-center">
              <md-button
                  href="javascript:void(0)"
                  class="md-simple md-round"
                  @click="manualRotateState = true"
              >
                <md-icon>refresh</md-icon>
              </md-button>
            </div>
          </template>
          <template slot="backRotateCardContent">
            <h5 class="card-title">
              Build a Better Future
            </h5>
            <p class="card-description">
              Contacter nous...<br>

              <md-button v-clipboard:copy="+33688888888">+33 6 68 94 23 85</md-button>
            </p>
            <div class="stats text-center">
              <md-button
                  href="mailto:xmahieu@abbaq.fr"
                  class="md-twitter md-just-icon md-round"
              >
                <i class="fa fa-envelope" />
              </md-button>
              <md-button
                  href="tel: +33668942385"
                  class="md-dribbble md-just-icon md-round"
              >
                <i class="fa fa-mobile" />
              </md-button>

            </div>
            <br>
            <md-button
                href="javascript:void(0)"
                class="md-simple md-round"
                @click="manualRotateState = false"
            >
              <md-icon>refresh</md-icon>
            </md-button>
          </template>
        </rotating-card>

          </div>
    </div>
  </div>
</template>

<script>
import {
  RotatingCard
} from "@/components";
import { isMobileOnly, isTablet } from 'mobile-device-detect';

export default {
  components: {
    RotatingCard
  },
  data() {
    return {
      isMobile: false,
      isTablet: false,
      isComputer: false,
      subscribe: null,
      manualRotateState: false,
      image: require("@/assets/img/bg7.jpg"),
      cardRotating: {
        cardRotatingBg1: require("@/assets/img/Abbaq/abbaqimage4.jpg"),
        cardRotatingBg2: require("@/assets/img/Abbaq/abbaqimage3.jpg"),
        logo: require("@/assets/img/Abbaq/logo/logo-abbaq.png"),
        cardRotatingBg3: require("@/assets/img/Abbaq/logo/logo-card-contact2.jpg"),
      }
    };
  },
  created() {
    this.isMobile = isMobileOnly;
    this.isTablet = isTablet;
    this.isComputer = !this.isTablet && !this.isMobile;
  },
  computed: {
    imageSubscribe() {
      return {
        backgroundImage: `url(${this.image})`
      };
    }
  }
};
</script>

<style lang="scss" scoped>
.d-flex-center {
  display: flex;
  align-items: center;
}
</style>
