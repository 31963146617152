<template>
  <md-toolbar
      id="toolbar"
      md-elevation="0"
      class="md-fixed"
      :class="{
      [`md-${type}`]: !navbarTransparent,
      [extraNavClasses]: navbarTransparent,
      'md-transparent': navbarTransparent
    }"
      :color-on-scroll="colorOnScroll"
  >
    <div class="md-toolbar-row md-collapse-lateral">
      <div class="md-toolbar-section-start">
        <h3 class="md-title">
          <img
              id="logo"
              :src="logoSrc"
              alt="abbaq-logo"
              class="logo"
          >
        </h3>
      </div>
      <div class="md-toolbar-section-end">
        <md-button
            class="md-just-icon md-simple md-toolbar-toggle"
            :class="{ toggled: toggledClass }"
            @click="toggleNavbarMobile()"
        >
          <span class="icon-bar"/>
          <span class="icon-bar"/>
          <span class="icon-bar"/>
        </md-button>

        <div class="md-collapse">
          <div id="menu" class="md-collapse-wrapper">
            <mobile-menu nav-mobile-section="false">
              <!-- Here you can add your items from the section-start of your toolbar -->
            </mobile-menu>

            <md-list>
              <md-list-item
                  id="baccueil"
                  @mouseover="hoverOver"
                  @mouseout="hoverOut"
                  href="/"
              >
                <div id="itembaccueil" :class="classesMenu">
                  <i class="material-icons">home</i>
                  Accueil
                </div>
              </md-list-item>
              <md-list-item
                  id="baboutUs"
                  @mouseover="hoverOver"
                  @mouseout="hoverOut"
                  href="/qui-sommes-nous"
              >
                <div id="itembaboutUs" :class="classesMenu">
                  <i class="material-icons">people</i>
                  QUI SOMMES NOUS?
                </div>
              </md-list-item>
              <md-list-item
                  id="bmetier"
                  @mouseover="hoverOver"
                  @mouseout="hoverOut"
                  href="/nos-projets"
              >

                <div id="itembmetier" :class="classesMenu">
                  <i class="material-icons">apartment</i>
                  Nos projets
                </div>

              </md-list-item>
              <md-list-item
                  id="brea"
                  @mouseover="hoverOver"
                  @mouseout="hoverOut"
                  href="/nos-realisations"
              >

                <div id="itembrea" :class="classesMenu">
                  <i class="material-icons">color_lens</i>
                  Nos Réalisations
                </div>

              </md-list-item>
              <md-list-item
                  id="bteam"
                  @mouseover="hoverOver"
                  @mouseout="hoverOut"
                  href="/contacter-nous"
              >

                <div id="itembteam" :class="classesMenu">
                  <i class="material-icons">alternate_email</i>
                  Contact
                </div>
              </md-list-item>
              <md-list-item
                  id="bcontact"
                  @mouseover="hoverOver"
                  @mouseout="hoverOut"
                  href="/home"
              >
                  <div class="md-list-item-content">
                    <md-button
                        class="md-red md-just-icon md-round"
                    ><md-icon>language</md-icon><div class="langue">en</div></md-button>
                  </div>
              </md-list-item>
            </md-list>
          </div>
        </div>
      </div>
    </div>
  </md-toolbar>
</template>

<script>
let resizeTimeout;

function resizeThrottler(actualResizeHandler) {
  // ignore resize events as long as an actualResizeHandler execution is in the queue
  if (!resizeTimeout) {
    resizeTimeout = setTimeout(() => {
      resizeTimeout = null;
      actualResizeHandler();

      // The actualResizeHandler will execute at a rate of 15fps
    }, 66);
  }
}

import MobileMenu from "@/layout/MobileMenu";

export default {
  components: {
    MobileMenu
  },
  props: {
    logoSrc: {
      type: String,
      default: require("@/assets/img/Abbaq/logo/logoAbbaqSmall.png")
    },
    logoSrcBlack: {
      type: String,
      default: require("@/assets/img/Abbaq/logo/logoAbbaqSmallBlack.png")
    },
    type: {
      type: String,
      default: "white",
      validator(value) {
        return [
          "white",
          "default",
          "primary",
          "danger",
          "success",
          "warning",
          "info",
          "dark"
        ].includes(value);
      }
    },
    colorOnScroll: {
      type: Number,
      default: 0
    },
    navbarTransparent: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      changeSize: false,
      currentItemMenu: null,
      classesMenu: ['itemMenu'],
      docs_link:
          "https://demos.creative-tim.com/vue-material-kit-pro/documentation/",
      extraNavClasses: "",
      toggledClass: false,
      brand: "Abbaq",
    };
  },
  computed: {
    showDownload() {
      const excludedRoutes = ["index"];
      return excludedRoutes.every(r => r !== this.$route.name);
    }
  },
  mounted() {
    document.addEventListener("scroll", this.scrollListener);
  },
  beforeDestroy() {
    document.removeEventListener("scroll", this.scrollListener);
    document.addEventListener('change', this.slideListener);
  },
  methods: {
    hoverOver: function (event) {
      let currentItemId = event.currentTarget.id;
      if(currentItemId != null) {
        this.currentItemMenu = document.getElementById('item' + currentItemId);
        this.classesMenu = ["itemMenu"];
        if(this.currentItemMenu != null) {
          this.currentItemMenu.classList.remove("zoomOut");
          this.currentItemMenu.classList.add("zoomIn");
        }
      }
    },
    hoverOut: function () {
      if(this.currentItemMenu != null) {
        this.currentItemMenu.classList.remove("zoomIn");
        this.currentItemMenu.classList.add("zoomOut");
      }
    },
    bodyClick() {
      let bodyClick = document.getElementById("bodyClick");

      if (bodyClick === null) {
        let body = document.querySelector("body");
        let elem = document.createElement("div");
        elem.setAttribute("id", "bodyClick");
        body.appendChild(elem);

        let bodyClick = document.getElementById("bodyClick");
        bodyClick.addEventListener("click", this.toggleNavbarMobile);
      } else {
        bodyClick.remove();
      }
    },
    toggleNavbarMobile() {
      this.NavbarStore.showNavbar = !this.NavbarStore.showNavbar;
      this.toggledClass = !this.toggledClass;
      this.bodyClick();
    },
    handleScroll() {
      let scrollValue =
          document.body.scrollTop || document.documentElement.scrollTop;
      let navbarColor = document.getElementById("toolbar");
      let logo = document.getElementById('logo');

      this.currentScrollValue = scrollValue;
      if (this.colorOnScroll > 0 && scrollValue > this.colorOnScroll) {
        this.extraNavClasses = `md-${this.type}`;
        navbarColor.classList.remove("md-transparent");
        logo.setAttribute("src", this.logoSrcBlack);
        this.classesMenu = ['itemMenu'];
      } else {
        if (this.extraNavClasses) {
          this.extraNavClasses = "";
          navbarColor.classList.add("md-transparent");
        }
        logo.setAttribute("src", this.logoSrc);
      }
    },
    scrollListener() {
      resizeThrottler(this.handleScroll);
    }
  }
};
</script>
