<template>
  <div class="wrapper">
    <!-- Tower -->
    <div class="cd-section">
      <div
          id="sectionTower"
          class="section section-gray pb-0"
      >
        <div class="container">
          <div id="cards">
            <template v-if="!sectionCardPlain && !sectionMorphingCards">
              <div class="md-layout">
                <div class="md-layout-item md-large-size-33 md-small-size-100">
                  <md-card id="img1Elt" :style="bgFullCards(abbaq.abbaqRea1)">
                    <md-card-content>
                      <a
                          id="img1"
                          @mouseover="hoverOver"
                          @mouseout="hoverOut"
                          @click="imageModal(abbaq.abbaqRea1)"
                          href="javascript:void(0)"
                      >
                        <h5 class="card-category card-category-social">
                          <br>
                          <br>
                          <br>
                          <br>
                          <br>
                        </h5>
                      </a>
                    </md-card-content>
                  </md-card>
                  <md-card id="img2Elt" :style="bgFullCards(abbaq.abbaqRea2)">
                    <md-card-content>
                      <a
                          id="img2"
                          @mouseover="hoverOver"
                          @mouseout="hoverOut"
                          @click="imageModal(abbaq.abbaqRea2)"
                          href="javascript:void(0)"
                      >
                        <h5 class="card-category card-category-social">
                          <br>
                          <br>
                          <br>
                          <br>
                          <br>
                        </h5>
                      </a>
                    </md-card-content>
                  </md-card>
                </div>
                <div
                    class="md-layout-item md-large-size-33 md-medium-50 md-small-size-100"
                >
                  <md-card id="img3Elt" :style="bgFullCards(abbaq.abbaqRea3)">
                    <md-card-content>
                      <a
                          id="img3"
                          @mouseover="hoverOver"
                          @mouseout="hoverOut"
                          @click="imageModal(abbaq.abbaqRea3)"
                          href="javascript:void(0)"
                      >
                        <h5 class="card-category card-category-social">
                          <br>
                          <br>
                          <br>
                          <br>
                          <br>
                        </h5>
                      </a>
                    </md-card-content>
                  </md-card>

                  <md-card
                          id="img4Elt"
                          :style="bgFullCards(abbaq.abbaqRea4)"
                  >
                    <md-card-content>
                      <a
                          id="img4"
                          @mouseover="hoverOver"
                          @mouseout="hoverOut"
                          @click="imageModal(abbaq.abbaqRea4)"
                          href="javascript:void(0)"
                      >
                        <h5 class="card-category card-category-social">
                          <br>
                          <br>
                          <br>
                          <br>
                          <br>
                        </h5>
                      </a>
                    </md-card-content>
                  </md-card>
                </div>
                <div class="md-layout-item md-large-size-33 md-small-size-100">
                    <h2 class="titleproject">Pascal Towers, La Défense, Paris</h2>
                    <div class="descriptionProject">Both Pascal office towers in La Défense, Paris’s business district, were purchased from a Saudi Prince. The main tenants for the 70,000 sqm of office space were the French ministry of the environment and SNCF, France’s railway operator. The towers were restructured and the contracts extended for significantly longer maturity. The towers were subsequently sold to a leading US investment bank.</div>
                </div>
              </div>
              <!-- end Blog Cards -->

            </template>
          </div>
        </div>
      </div>
    </div>

    <!-- sherton -->
    <div class="cd-section">
      <div
          id="sectionSheraton"
          class="section pb-0"
      >
        <div class="container">
          <div id="cards2">
            <template v-if="!sectionCardPlain && !sectionMorphingCards">
              <div class="md-layout">
                <div class="md-layout-item md-large-size-33 md-small-size-100">
                  <h2 class="titleproject">Sheraton San Siro, Milan</h2>
                  <div class="descriptionProject">Acquisition of a 35,000 sqm hotel in Milan from a large Italian financial institution in the context of a bankruptcy procedure. The 315 room Sheraton hotel was completely restructured over a three-year period and rebranded as a Sheraton, a brand of Marriott International, Inc.</div>
                </div>
                <div class="md-layout-item md-large-size-33 md-small-size-100">
                  <md-card id="img5Elt" :style="bgFullCards(abbaq.abbaqRea5)">
                    <md-card-content>
                      <a
                          id="img5"
                          @mouseover="hoverOver"
                          @mouseout="hoverOut"
                          @click="imageModal(abbaq.abbaqRea5)"
                          href="javascript:void(0)"
                      >
                        <h5 class="card-category card-category-social">
                          <br>
                          <br>
                          <br>
                          <br>
                          <br>
                        </h5>
                      </a>
                    </md-card-content>
                  </md-card>
                  <md-card id="img6Elt" :style="bgFullCards(abbaq.abbaqRea6)">
                    <md-card-content>
                      <a
                          id="img6"
                          @mouseover="hoverOver"
                          @mouseout="hoverOut"
                          @click="imageModal(abbaq.abbaqRea6)"
                          href="javascript:void(0)"
                      >
                        <h5 class="card-category card-category-social">
                          <br>
                          <br>
                          <br>
                          <br>
                          <br>
                        </h5>
                      </a>
                    </md-card-content>
                  </md-card>
                </div>
                <div
                    class="md-layout-item md-large-size-33 md-medium-50 md-small-size-100"
                >
                  <md-card id="img7Elt" :style="bgFullCards(abbaq.abbaqRea7)">
                    <md-card-content>
                      <a
                          id="img7"
                          @mouseover="hoverOver"
                          @mouseout="hoverOut"
                          @click="imageModal(abbaq.abbaqRea7)"
                          href="javascript:void(0)"
                      >
                        <h5 class="card-category card-category-social">
                          <br>
                          <br>
                          <br>
                          <br>
                          <br>
                        </h5>
                      </a>
                    </md-card-content>
                  </md-card>

                  <md-card
                      id="img8Elt"
                      :style="bgFullCards(abbaq.abbaqRea8)"
                  >
                    <md-card-content>
                      <a
                          id="img8"
                          @mouseover="hoverOver"
                          @mouseout="hoverOut"
                          @click="imageModal(abbaq.abbaqRea8)"
                          href="javascript:void(0)"
                      >
                        <h5 class="card-category card-category-social">
                          <br>
                          <br>
                          <br>
                          <br>
                          <br>
                        </h5>
                      </a>
                    </md-card-content>
                  </md-card>
                </div>
              </div>
              <!-- end Blog Cards -->

            </template>
          </div>
        </div>
      </div>
    </div>

    <!-- sherton Malpensa -->
    <div class="cd-section">
      <div
          id="sectionSheratonMalpensa"
          class="section section-gray pb-0"
      >
        <div class="container">
          <div id="cards3">
            <template v-if="!sectionCardPlain && !sectionMorphingCards">
              <div class="md-layout">
                <div class="md-layout-item md-large-size-33 md-small-size-100">
                  <md-card id="img9Elt" :style="bgFullCards(abbaq.abbaqRea9)">
                    <md-card-content>
                      <a
                          id="img9"
                          @mouseover="hoverOver"
                          @mouseout="hoverOut"
                          @click="imageModal(abbaq.abbaqRea9)"
                          href="javascript:void(0)"
                      >
                        <h5 class="card-category card-category-social">
                          <br>
                          <br>
                          <br>
                          <br>
                          <br>
                        </h5>
                      </a>
                    </md-card-content>
                  </md-card>
                  <md-card id="img10Elt" :style="bgFullCards(abbaq.abbaqRea10)">
                    <md-card-content>
                      <a
                          id="img10"
                          @mouseover="hoverOver"
                          @mouseout="hoverOut"
                          @click="imageModal(abbaq.abbaqRea10)"
                          href="javascript:void(0)"
                      >
                        <h5 class="card-category card-category-social">
                          <br>
                          <br>
                          <br>
                          <br>
                          <br>
                        </h5>
                      </a>
                    </md-card-content>
                  </md-card>
                </div>
                <div
                    class="md-layout-item md-large-size-33 md-medium-50 md-small-size-100"
                >
                  <md-card id="img11Elt" :style="bgFullCards(abbaq.abbaqRea11)">
                    <md-card-content>
                      <a
                          id="img11"
                          @mouseover="hoverOver"
                          @mouseout="hoverOut"
                          @click="imageModal(abbaq.abbaqRea11)"
                          href="javascript:void(0)"
                      >
                        <h5 class="card-category card-category-social">
                          <br>
                          <br>
                          <br>
                          <br>
                          <br>
                        </h5>
                      </a>
                    </md-card-content>
                  </md-card>

                  <md-card
                      id="img12Elt"
                      :style="bgFullCards(abbaq.abbaqRea13)"
                  >
                    <md-card-content>
                      <a
                          id="img12"
                          @mouseover="hoverOver"
                          @mouseout="hoverOut"
                          @click="imageModal(abbaq.abbaqRea13)"
                          href="javascript:void(0)"
                      >
                        <h5 class="card-category card-category-social">
                          <br>
                          <br>
                          <br>
                          <br>
                          <br>
                        </h5>
                      </a>
                    </md-card-content>
                  </md-card>
                </div>
                <div class="md-layout-item md-large-size-33 md-small-size-100">
                  <h2 class="titleproject">Sheraton Malpensa, Milan</h2>
                  <div class="descriptionProject">The Sheraton Malpensa Hotel & Conference center is a 4-star upscale hotel with 437 rooms and a 30 room conference center for up to 1100 participants. We took a minority interest in the management of the hotel following a debt restructuring lead by our partners.
                  </div>
                </div>
              </div>
              <!-- end Blog Cards -->

            </template>
          </div>
        </div>
      </div>
    </div>

    <!-- Golden Visa -->
    <div class="cd-section">
      <div
          id="sectionGoldenVisa"
          class="section pb-0"
      >
        <div class="container">
          <div id="cards4">
            <template v-if="!sectionCardPlain && !sectionMorphingCards">
              <div class="md-layout">
                <div class="md-layout-item md-large-size-33 md-small-size-100">
                  <h2 class="titleproject">Golden Visa Apartments, Madrid</h2>
                  <div class="descriptionProject">A total of four buildings representing approximately 4,800 sqm were restructured or entirely rebuilt into 36 luxury apartments in the immediate vicinity of the Plaza Major. All apartments were sold to Asian investors as part of the Spanish golden visa program.</div>
                </div>
                <div class="md-layout-item md-large-size-33 md-small-size-100">
                  <md-card id="img13Elt" :style="bgFullCards(abbaq.abbaqRea16)">
                    <md-card-content>
                      <a
                          id="img13"
                          @mouseover="hoverOver"
                          @mouseout="hoverOut"
                          @click="imageModal(abbaq.abbaqRea16)"
                          href="javascript:void(0)"
                      >
                        <h5 class="card-category card-category-social">
                          <br>
                          <br>
                          <br>
                          <br>
                          <br>
                        </h5>
                      </a>
                    </md-card-content>
                  </md-card>
                  <md-card id="img15Elt" :style="bgFullCards(abbaq.abbaqRea17)">
                    <md-card-content>
                      <a
                          id="img15"
                          @mouseover="hoverOver"
                          @mouseout="hoverOut"
                          @click="imageModal(abbaq.abbaqRea6)"
                          href="javascript:void(0)"
                      >
                        <h5 class="card-category card-category-social">
                          <br>
                          <br>
                          <br>
                          <br>
                          <br>
                        </h5>
                      </a>
                    </md-card-content>
                  </md-card>
                </div>
                <div
                    class="md-layout-item md-large-size-33 md-medium-50 md-small-size-100"
                >
                  <md-card id="img16Elt" :style="bgFullCards(abbaq.abbaqRea14)">
                    <md-card-content>
                      <a
                          id="img16"
                          @mouseover="hoverOver"
                          @mouseout="hoverOut"
                          @click="imageModal(abbaq.abbaqRea14)"
                          href="javascript:void(0)"
                      >
                        <h5 class="card-category card-category-social">
                          <br>
                          <br>
                          <br>
                          <br>
                          <br>
                        </h5>
                      </a>
                    </md-card-content>
                  </md-card>

                  <md-card
                      id="img17Elt"
                      :style="bgFullCards(abbaq.abbaqRea15)"
                  >
                    <md-card-content>
                      <a
                          id="img17"
                          @mouseover="hoverOver"
                          @mouseout="hoverOut"
                          @click="imageModal(abbaq.abbaqRea15)"
                          href="javascript:void(0)"
                      >
                        <h5 class="card-category card-category-social">
                          <br>
                          <br>
                          <br>
                          <br>
                          <br>
                        </h5>
                      </a>
                    </md-card-content>
                  </md-card>
                </div>
              </div>
              <!-- end Blog Cards -->

            </template>
          </div>
        </div>
      </div>
    </div>

    <!-- ericson -->
    <div class="cd-section">
      <div
          id="sectionEricson"
          class="section section-gray  pb-0"
      >
        <div class="container">
          <div id="cards5">
            <template v-if="!sectionCardPlain && !sectionMorphingCards">
              <div class="md-layout">
                <div class="md-layout-item md-large-size-33 md-small-size-100">
                  <md-card id="img18Elt" :style="bgFullCards(abbaq.abbaqRea18)">
                    <md-card-content>
                      <a
                          id="img18"
                          @mouseover="hoverOver"
                          @mouseout="hoverOut"
                          @click="imageModal(abbaq.abbaqRea18)"
                          href="javascript:void(0)"
                      >
                        <h5 class="card-category card-category-social">
                          <br>
                          <br>
                          <br>
                          <br>
                          <br>
                        </h5>
                      </a>
                    </md-card-content>
                  </md-card>
                  <md-card id="img19Elt" :style="bgFullCards(abbaq.abbaqRea19)">
                    <md-card-content>
                      <a
                          id="img19"
                          @mouseover="hoverOver"
                          @mouseout="hoverOut"
                          @click="imageModal(abbaq.abbaqRea19)"
                          href="javascript:void(0)"
                      >
                        <h5 class="card-category card-category-social">
                          <br>
                          <br>
                          <br>
                          <br>
                          <br>
                        </h5>
                      </a>
                    </md-card-content>
                  </md-card>
                </div>
                <div
                    class="md-layout-item md-large-size-33 md-medium-50 md-small-size-100"
                >
                  <md-card id="img20Elt" :style="bgFullCards(abbaq.abbaqRea20)">
                    <md-card-content>
                      <a
                          id="img20"
                          @mouseover="hoverOver"
                          @mouseout="hoverOut"
                          @click="imageModal(abbaq.abbaqRea20)"
                          href="javascript:void(0)"
                      >
                        <h5 class="card-category card-category-social">
                          <br>
                          <br>
                          <br>
                          <br>
                          <br>
                        </h5>
                      </a>
                    </md-card-content>
                  </md-card>

                  <md-card
                      id="img21Elt"
                      :style="bgFullCards(abbaq.abbaqRea21)"
                  >
                    <md-card-content>
                      <a
                          id="img21"
                          @mouseover="hoverOver"
                          @mouseout="hoverOut"
                          @click="imageModal(abbaq.abbaqRea21)"
                          href="javascript:void(0)"
                      >
                        <h5 class="card-category card-category-social">
                          <br>
                          <br>
                          <br>
                          <br>
                          <br>
                        </h5>
                      </a>
                    </md-card-content>
                  </md-card>
                </div>

                <div class="md-layout-item md-large-size-33 md-small-size-100">
                  <h2 class="titleproject">Ericsson Headquarters, Roma</h2>
                  <div class="descriptionProject">10 buildings in the outskirts of Rome representing more than 10,000 sqm of office space were purchased from a group of Italian investors. The building housed Ericsson’s Headquarters for southern Europe. The contract was extended for a much longer duration, a large part of the land was rezoned for residential use generating substantial value. The property was then sold to a large Italian institutional investor.</div>
                </div>
              </div>
              <!-- end Blog Cards -->

            </template>
          </div>
        </div>
      </div>
    </div>

    <div
        class="modal modal-bg"
        :class="{ show: imageModalClassic }"
    >
      <modal
          v-if="imageModalClassic"
          @close="imageModalClassic = false"
      >
        <template slot="header">
          <h4 class="modal-title">
            {{ imageTitle }}
          </h4>
          <md-button
              class="md-simple md-close md-just-icon md-round modal-default-button"
              @click="imageModalClassic = false"
          >
            <md-icon>clear</md-icon>
          </md-button>
        </template>

        <template slot="body">

          <img :src="srcImageValue" width="100%" />
        </template>

        <template slot="footer">
          <md-button
              class="md-danger md-simple"
              @click="imageModalClassic = false"
          >
            Close
          </md-button>
        </template>
      </modal>
    </div>
  </div>
</template>

<script>
import {
  BlogCard,
    Modal,
} from "@/components";

export default {
  components: {
    Modal
  },
  props: {
    sectionCardPlain: Boolean,
    sectionMorphingCards: Boolean
  },
  methods: {
    imageModal(imageSrc, imageTitle) {
        this.imageTitle = imageTitle;
        this.srcImageValue = imageSrc;
        this.imageModalClassic = true;
    },
    bgFullCards(cardImage) {
      return {
        backgroundSize: '100% 100%',
        backgroundImage: `url(${cardImage})`
      };
    },
    hoverOver: function (event) {
      let currentItemId = event.currentTarget.id + 'Elt';


      if (currentItemId != null) {
        this.currentEscapeButton = document.getElementById(currentItemId);
        if (this.currentEscapeButton != null) {
          this.currentEscapeButton.classList.remove("backgroundImageOut");
          this.currentEscapeButton.classList.add("backgroundImageIn");
        }
      }
    },
    hoverOut: function () {
      if (this.currentEscapeButton != null) {
        this.currentEscapeButton.classList.remove("backgroundImageIn");
        this.currentEscapeButton.classList.add("backgroundImageOut");
      }
    }
  },
  data() {
    return {
      hover4:false,
      imageTitle:null,
      srcImageValue: null,
      imageModalClassic: false,
      imageTitleHeader: {
        titleRea1: 'Pascal office towers in La Défense',
        titleRea2: 'Golden Visa Apartments, Madrid',
        titleRea3: 'Sheraton San Siro, Milan',
        titleRea4: 'Ericson Headquarters',
        titleRea5: 'The Sheraton Malpensa Hotel & Conference center',
      },
      abbaq: {
        abbaqRea1: require("@/assets/img/Abbaq/realisation_abbaq/image1.jpg"),
        abbaqRea2: require("@/assets/img/Abbaq/realisation_abbaq/image2.png"),
        abbaqRea3: require("@/assets/img/Abbaq/realisation_abbaq/image3.jpg"),
        abbaqRea4: require("@/assets/img/Abbaq/realisation_abbaq/image4.jpg"),
        abbaqRea5: require("@/assets/img/Abbaq/realisation_abbaq/image5.jpg"),
        abbaqRea6: require("@/assets/img/Abbaq/realisation_abbaq/image6.jpg"),
        abbaqRea7: require("@/assets/img/Abbaq/realisation_abbaq/image7.png"),
        abbaqRea8: require("@/assets/img/Abbaq/realisation_abbaq/image8.jpg"),
        abbaqRea9: require("@/assets/img/Abbaq/realisation_abbaq/image9.png"),
        abbaqRea10: require("@/assets/img/Abbaq/realisation_abbaq/image11.png"),
        abbaqRea11: require("@/assets/img/Abbaq/realisation_abbaq/image12.png"),
        abbaqRea13: require("@/assets/img/Abbaq/realisation_abbaq/image15.png"),
        abbaqRea14: require("@/assets/img/Abbaq/realisation_abbaq/image16.png"),
        abbaqRea15: require("@/assets/img/Abbaq/realisation_abbaq/image17.png"),
        abbaqRea16: require("@/assets/img/Abbaq/realisation_abbaq/image18.png"),
        abbaqRea17: require("@/assets/img/Abbaq/realisation_abbaq/image19.png"),
        abbaqRea18: require("@/assets/img/Abbaq/realisation_abbaq/image20.jpg"),
        abbaqRea19: require("@/assets/img/Abbaq/realisation_abbaq/image21.jpg"),
        abbaqRea20: require("@/assets/img/Abbaq/realisation_abbaq/image22.jpg"),
        abbaqRea21: require("@/assets/img/Abbaq/realisation_abbaq/image23.jpg")
      }
    };
  }
};
</script>
