<template>
  <div class=" wrapper">
    <parallax
        class="page-header header-filter header-small"
        parallax-active="true"
        :style="headerStyle"
    >
      <div class="container">
        <div class="md-layout">
          <div
              class="md-layout-item md-size-66 md-small-size-100 mx-auto text-center"
          >
            <h1 class="title">
              Nos Projets
            </h1>
            <h4>
              ABBAQ développe des projets immobiliers à tous les niveaux avec une expertise particulière en résidentiel,
              bureaux et hôtellerie.
            </h4>
          </div>
        </div>
      </div>
    </parallax>
    <div class="main main-raised">
      <div class="section section-about-description text-center pb-0">
        <div class="container">
          <div class="md-layout">
            <div class="md-layout-item md-size-66 md-small-size-100 mx-auto">
              <h5 class="description">
                Du repositionnement des actifs immobiliers aux projets entièrement nouveaux, ABBAQ recherche des
                développements immobiliers alternatifs pour générer de la valeur.<br><br>
                Avec des projets allant de 2 000 m² à 100 000 m², ABBAQ offre des rendements ajustés au risque. Nous
                nous concentrons sur les propriétés de taille moyenne (20 à 100 M€) et visons un rendement annualisé à
                deux chiffres grâce à l'exécution de programmes à forte valeur ajoutée.<br><br>
                Notre expérience en gestion d'actifs nous permet de rechercher des opportunités qui résultent à la fois
                de considérations locales et macro.<br>
              </h5>
            </div>
          </div>
        </div>
      </div>

      <div class="section section-login section-gray text-center pb-0">
        <div class="container">
          <div class="md-layout">
            <div class="md-layout-item md-size-66 md-small-size-100 mx-auto">
              <login-card header-color="red">
                <h4
                    slot="title"
                    class="card-title"
                >
                  ACCES PARTENAIRES
                </h4>

                <div
                    slot="description"
                    class="description"
                >
                  <div v-show="alertIntro">

                    Connectez-vous pour accéder à votre projet
                  </div>

                  <div
                      id="alertMailDanger"
                      class="alert alert-danger"
                      v-show="alertDanger">
                    <div class="container">
                      <div class="alert-icon">
                        <md-icon>error_outline</md-icon>
                      </div>
                      {{ erreurMessage }}
                    </div>
                  </div>
                </div>
                <md-field
                    slot="inputs"
                    class="md-form-group has-danger"
                >
                  <md-icon>email</md-icon>
                  <label>Email...</label>
                  <md-input
                      v-model="email"
                      type="email"
                  />
                </md-field>
                <md-field
                    slot="inputs"
                    class="md-form-group has-danger"
                >
                  <md-icon>lock_outline</md-icon>
                  <label>Password...</label>
                  <md-input v-model="password" />
                </md-field>
                <md-button
                    slot="footer"
                    class="md-simple md-danger md-lg"
                    @click="checkForm"
                >
                  Log in
                </md-button>
              </login-card>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AOS from "aos";
import "aos/dist/aos.css";
import Mixins from "@/plugins/basicMixins";
import { LoginCard } from "@/components";

import CardsSection from "./abbaq/CardsSection";

export default {
  components: {
    LoginCard
  },
  mixins: [Mixins.HeaderImage],
  bodyClass: "nos-projets",
  data() {
    return {
      speciality: "",
      erreurMessage: "ouais",
      alertDanger : false,
      alertIntro : true,
      name: null,
      email: "",
      password: "",
      image: require("@/assets/img/realisations/la-defence-tours-Pascal-slide-1.png"),
      aboutTeam: {
        aboutTeam1: require("@/assets/img/faces/marc.jpg"),
        aboutTeam2: require("@/assets/img/faces/kendall.jpg"),
        aboutTeam3: require("@/assets/img/faces/christian.jpg"),
        aboutTeam4: require("@/assets/img/faces/avatar.jpg")
      },
      aboutOffice: {
        aboutOffice1: require("@/assets/img/examples/office2.jpg"),
        aboutOffice2: require("@/assets/img/examples/office4.jpg"),
        aboutOffice3: require("@/assets/img/examples/office3.jpg"),
        aboutOffice4: require("@/assets/img/examples/office5.jpg"),
        aboutOffice5: require("@/assets/img/examples/office1.jpg")
      }
    };
  },
  created() {
    AOS.init();
  },
  methods: {
    checkForm() {
      if(this.email == "") {
         this.erreurMessage = "Vous devez rentrer un email pour vous connecter";
      } else if(this.password == "") {
        this.erreurMessage = "Vous devez rentrer un mot de passe pour vous connecter"
      } else {
        this.erreurMessage = "L'email n'est pas un identifiant reconnu par notre système";
      }

      this.alertDanger = true;
      this.alertIntro = false;

      setTimeout(() => {
        this.alertIntro = true;
        this.alertDanger = false;
      }, 3000);

    }
  }
};
</script>

<style lang="scss" scoped>
.mt-2 {
  margin-top: 1.5rem;
}
</style>
