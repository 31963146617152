<template>
  <div class="section section-components pt-0">
    <div class="container">
      <div class="md-layout">
        <div class="md-layout-item md-size-40 md-small-size-100">
          <h3 class="title">
            Présentation
          </h3>
          <h6 class="description">
            Notre métier
          </h6>
          <h5 class="description">
            ABBAQ est une société active dans le repositionnement financier des actifs immobiliers de
            taille moyenne avec une approche écoresponsable sur des marchés en croissance en Europe. <br><br>
            ABBAQ co-investit également sur des projets plus importants et fournit des services de
            conseil en gestion de projet et en financement structuré. La combinaison des connaissances
            locales et de l&#39;exposition internationale crée de la valeur dans un environnement connu pour
            ses pratiques complexes.
          </h5>
        </div>
        <div class="md-layout-item md-size-50 md-small-size-100 mx-auto">
          <div class="image-container">
            <img
              class="components-macbook"
              :src="sectionComponents.img1"
              alt=""
            >
            <img
              class="table-img"
              :src="sectionComponents.img2"
              alt=""
            >
            <img
              class="coloured-card-btn-img"
              :src="sectionComponents.img4"
              alt=""
            >
            <img
              class="coloured-card-img"
              :src="sectionComponents.img5"
              alt=""
            >
            <img
              class="social-img"
              :src="sectionComponents.img6"
              alt=""
            >
            <img
              class="pin-btn-img"
              :src="sectionComponents.img3"
              alt=""
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Components",
  data() {
    return {
      sectionComponents: {
        img1: require("@/assets/img/Abbaq/site-construction.jpg"),
        img2: require("@/assets/img/Abbaq/investissement1.jpg"),
        img3: require("@/assets/img/Abbaq/investissement2.jpg"),
        img4: require("@/assets/img/Abbaq/investissement3.png"),
        img5: require("@/assets/img/Abbaq/plan-architect.jpg"),
        img6: require("@/assets/img/Abbaq/reference.jpg")
      }
    };
  }
};
</script>

<style lang="css"></style>
